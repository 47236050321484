import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSearchNav = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="search-nav-svg"
    viewBox="0 0 40 40"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h40v40H0z" />
      <circle
        cx={18.182}
        cy={18.18}
        r={13.182}
        stroke="#1853a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        stroke="#1853a3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M27.727 27.725 35 34.998"
      />
    </g>
  </svg>
);
export default SvgSearchNav;
