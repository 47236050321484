export const compareAlphabetically = (
  candidateA: string | null | undefined,
  candidateB: string | null | undefined
) => {
  const normalizedValueA = (candidateA ?? '').toLowerCase();
  const normalizedValueB = (candidateB ?? '').toLowerCase();

  if (normalizedValueA < normalizedValueB) {
    return -1;
  }

  if (normalizedValueA > normalizedValueB) {
    return 1;
  }

  return 0;
};
