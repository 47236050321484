import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAddPhotoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 38" {...props}>
    <path
      fill="currentColor"
      d="M3.342 37.992Q1.992 37.992 1 37t-.992-2.342L0 14.008C0 13.008 0 12 1 11c.791-.79 1-1 2.334-1h6.658l3.716-3.708A1 1 0 0 1 14.414 6H27a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H16l-4.008 4.008h-8V34h32L36 17a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1l.008 17.658q0 1.35-1.008 2.342t-2.325.992zM37 12a1 1 0 0 1-1-1V8h-3a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h3V1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3.233a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H40v3a1 1 0 0 1-1 1zM19.984 30.767q3.666 0 6.175-2.509t2.508-6.175-2.508-6.158-6.175-2.492-6.159 2.492-2.491 6.158 2.492 6.175q2.49 2.508 6.158 2.509m0-3.334q-2.284 0-3.8-1.533t-1.517-3.817 1.517-3.8q1.516-1.516 3.8-1.516t3.816 1.516q1.534 1.517 1.534 3.8T23.8 25.9q-1.534 1.533-3.817 1.533"
    />
  </svg>
);
export default SvgAddPhotoIcon;
