import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMdrtCenterLogoReverse = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 288 201.944" {...props}>
    <defs>
      <clipPath id="mdrt-center-logo-reverse_svg__a" clipPathUnits="userSpaceOnUse">
        <path d="M0 151.458h216V0H0Z" />
      </clipPath>
    </defs>
    <g
      clipPath="url(#mdrt-center-logo-reverse_svg__a)"
      transform="matrix(1.33333 0 0 -1.33333 0 201.944)"
    >
      <path
        d="M0 0a8.766 8.766 0 0 1-8.766-8.766v-53.965A8.766 8.766 0 0 1 0-71.497a8.766 8.766 0 0 1 8.766 8.766v53.965A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(129.41 151.458)"
      />
      <path
        d="M0 0h-94.683a8.766 8.766 0 0 1-8.766-8.766 8.766 8.766 0 0 1 8.766-8.766H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(103.449 97.438)"
      />
      <path
        d="M0 0h-94.682a8.766 8.766 0 0 1-8.766-8.766 8.765 8.765 0 0 1 8.766-8.766H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(207.234 71.477)"
      />
      <path
        d="M0 0a8.766 8.766 0 0 1-8.766-8.766v-53.965A8.766 8.766 0 0 1 0-71.497a8.766 8.766 0 0 1 8.766 8.766v53.965A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(86.59 71.497)"
      />
      <path
        d="M0 0h-25.927a8.766 8.766 0 0 1 0-17.532H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#aa1a2d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(138.478 19.554)"
      />
      <path
        d="M0 0h-25.927a8.766 8.766 0 0 1 0-17.532H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#aa1a2d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(181.298 123.4)"
      />
      <path
        d="M0 0h-25.927a8.766 8.766 0 0 1 0-17.532H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#aa1a2d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(103.449 149.361)"
      />
      <path
        d="M0 0h-25.927a8.766 8.766 0 0 1 0-17.532H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#aa1a2d',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(60.628 45.515)"
      />
      <path
        d="M0 0h-51.862a8.766 8.766 0 0 1-8.766-8.766 8.765 8.765 0 0 1 8.766-8.766H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#ff6b0f',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(164.414 45.515)"
      />
      <path
        d="M0 0h-51.917a8.739 8.739 0 1 1 0-17.478H0A8.74 8.74 0 0 1 0 0"
        style={{
          fill: '#ff6b0f',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(207.261 97.438)"
      />
      <path
        d="M0 0h-51.862a8.766 8.766 0 0 1-8.766-8.766 8.765 8.765 0 0 1 8.766-8.766H0A8.766 8.766 0 0 1 0 0"
        style={{
          fill: '#ff6b0f',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(103.449 123.4)"
      />
      <path
        d="M0 0h-51.841a8.776 8.776 0 0 1-8.777-8.777 8.776 8.776 0 0 1 8.777-8.776H0A8.777 8.777 0 1 1 0 0"
        style={{
          fill: '#ff6b0f',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(60.618 71.497)"
      />
      <path
        d="M0 0h2.749l4.146-9.165c.317-.717.557-1.673.557-1.673h.08s.24.956.518 1.673L12.194 0h2.79l1.354-17.532H13.35l-.838 10.479a7 7 0 0 0 .041 1.197h-.08l-3.786-8.45H6.256L2.47-5.856h-.08s.08-.598.042-1.197l-.838-10.479h-2.989zm-11.754-2.669h-6.057V0h15.142v-2.669h-6.057v-14.863h-3.028z"
        style={{
          fill: '#fff',
          fillOpacity: 1,
          fillRule: 'nonzero',
          stroke: 'none',
        }}
        transform="translate(199.662 19.554)"
      />
    </g>
  </svg>
);
export default SvgMdrtCenterLogoReverse;
