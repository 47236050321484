import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <g fill="currentColor" clipPath="url(#twitter-icon_svg__a)">
      <path d="m6.688 6.194 8.668 11.586h1.962L8.651 6.194z" />
      <path d="M11.987.132C5.438.132.131 5.439.131 11.987c0 6.547 5.307 11.854 11.855 11.854 6.547 0 11.854-5.307 11.854-11.855C23.841 5.44 18.534.133 11.986.133m2.906 18.573-3.946-5.276-4.884 5.276H4.808l5.578-6.026-5.543-7.411h4.272l3.634 4.859 4.498-4.86h1.255l-5.192 5.61 5.855 7.828z" />
    </g>
    <defs>
      <clipPath id="twitter-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTwitterIcon;
