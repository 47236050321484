export enum ToastType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
}

export type ToastContent = {
  closeButtonLabel?: string;
  description?: string;
  isCloseButtonVisible?: boolean;
  type?: ToastType;
};
