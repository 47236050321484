export const getAppleDeviceInfo = () => {
  const ua = window.navigator.userAgent;
  const isIPad = Boolean(/ipad/iu.test(ua));
  const isIPhone = Boolean(/iphone/iu.test(ua));
  const isIosThirteenAndUp =
    Number(window.navigator.userAgent.match(/\b\d+(?:_\d+){1,2}\b/u)?.[0].split('_')[0]) >= 13;
  const isSafariSeventeenAndUp =
    Number(
      window.navigator.userAgent
        .match(/\b\d+(?:\.\d+){1,2} Safari\b/u)?.[0]
        .split(' ')[0]
        .split('.')[0]
    ) >= 17;

  return {
    isApple: isIPad || isIPhone || isSafariSeventeenAndUp,
    isIosThirteenAndUp: isIPhone && isIosThirteenAndUp,
    isIPad,
    isIPhone,
    isSafariSeventeenAndUp,
  };
};
