import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGlobeNav = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M20 34c7.732 0 14-6.268 14-14S27.732 6 20 6 6 12.268 6 20s6.268 14 14 14"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6.545 20.046h6.75m20.25 0h-6.75m-6.75-13.5c5.304 3.857 6.642 8.299 6.75 13.5m-6.75-13.5c-4.339 3.374-6.641 8.299-6.75 13.5m6.75-13.5v27m6.75-13.5c-.108 5.2-1.928 10.125-6.75 13.5m6.75-13.5h-13.5m6.75 13.5c-4.821-3.858-6.641-8.3-6.75-13.5"
    />
  </svg>
);
export default SvgGlobeNav;
