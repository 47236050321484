import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
    <rect width={28} height={2} x={10} y={11} fill="currentColor" rx={1} />
    <rect width={28} height={2} x={10} y={35} fill="currentColor" rx={1} />
    <rect width={28} height={2} x={10} y={23} fill="currentColor" rx={1} />
  </svg>
);
export default SvgMenu;
