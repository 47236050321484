'use client';

import { type ToastOptions, useToastState } from '@react-stately/toast';
import { createContext, type PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { ToastRegion } from '../components';
import { type ToastContent } from '../constants';

type ToastContextValues = {
  addToast: (toastData: ToastContent, options: ToastOptions) => void;
};

const ToastContext = createContext<ToastContextValues | undefined>(undefined);

export const GlobalToastContextProvider = ({ children, ...props }: PropsWithChildren) => {
  const state = useToastState<ToastContent>({
    hasExitAnimation: true,
    maxVisibleToasts: 3,
  });

  const addToast = useCallback(
    (toastData: ToastContent, options: ToastOptions) => {
      const isToastMessageUnique = !state.visibleToasts.some(
        (item) => item.content.description === toastData.description
      );

      if (isToastMessageUnique) {
        state.add(toastData, options);
      }
    },
    [state]
  );

  const contextValue = useMemo(() => ({ addToast }), [addToast]);

  return (
    <ToastContext.Provider value={contextValue}>
      {children}
      {state.visibleToasts.length > 0
        ? createPortal(<ToastRegion {...props} state={state} />, document.body)
        : null}
    </ToastContext.Provider>
  );
};

export const useGlobalToastContext = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};
