import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M11 16a1 1 0 1 1 2 0 1 1 0 0 1-2 0m0-8a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zm1-6C6.47 2 2 6.5 2 12A10 10 0 1 0 12 2m0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16"
    />
  </svg>
);
export default SvgAlertIcon;
