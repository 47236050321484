import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDrawerClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="M33.9 14.1 14.1 33.9m0-19.8 19.8 19.8"
    />
  </svg>
);
export default SvgDrawerClose;
