import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOptimizelyEditButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 148 148" {...props}>
    <path
      fill="#fff"
      d="M49.07 80.66v17.69A49.41 49.41 0 0 0 98.42 49H80.78a31.71 31.71 0 0 1-31.71 31.66M49.07 129.41A31.427 31.427 0 0 1 18.2 97.985 31.43 31.43 0 0 1 49.07 66.56V49.01a49 49 0 1 0-.14 98h.14z"
    />
    <path
      fill="#fff"
      d="M49.07 129.41v17.55a49 49 0 0 0 49-49H80.5a31.457 31.457 0 0 1-31.43 31.45M49.07 31.44V49a49 49 0 0 0 49-49H80.5a31.46 31.46 0 0 1-31.43 31.44M98.37 31.44V49a49 49 0 0 0 49-49h-17.59a31.457 31.457 0 0 1-31.41 31.44"
    />
  </svg>
);
export default SvgOptimizelyEditButtonIcon;
