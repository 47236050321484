import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className="external-link-icon_svg__footer__link-icon"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.852}
      d="M23.45 14.384v8.17c0 .584-.475 1.057-1.06 1.057H2.286a1.06 1.06 0 0 1-1.058-1.058V2.447c0-.584.474-1.058 1.058-1.058h8.17M17.101 1.389h6.35v6.349M11.802 12.86 23.273 1.389"
    />
  </svg>
);
export default SvgExternalLinkIcon;
