import classNames from 'classnames';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ComponentType,
  forwardRef,
  type ReactElement,
} from 'react';

import { type ChipTheme } from './chipTheme';
import styles from './chip.module.scss';

export type ChipProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  LinkComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
  dataTestId?: string;
  icon?: ReactElement;
  isExternal?: boolean;
  isSmall?: boolean;
  label: string | undefined;
  theme?: ChipTheme;
  to?: string;
  onClick?: () => void;
};

export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  (
    {
      LinkComponent,
      'aria-label': ariaLabel,
      className,
      dataTestId,
      icon,
      isExternal = false,
      isSmall = false,
      label,
      onClick,
      theme,
      to,
      type = 'button',
      ...props
    },
    ref
  ) => {
    const PARENT_CLASSNAME = 'chip';

    const chipClasses = classNames([
      styles[PARENT_CLASSNAME],
      {
        [styles[`${PARENT_CLASSNAME}--theme-${theme}`]]: theme,
        [styles[`${PARENT_CLASSNAME}--small`]]: isSmall,
        [styles[`${PARENT_CLASSNAME}--not-clickable`]]: !to && !type && !onClick,
      },
      className,
    ]);

    const content = (
      <>
        {icon && <div className={styles[`${PARENT_CLASSNAME}__icon-container`]}>{icon}</div>}
        {label}
      </>
    );

    const sharedProps = {
      ...props,
      'aria-label': ariaLabel || label,
      className: chipClasses,
      'data-testid': dataTestId,
      onClick,
    };
    const buttonProps = {
      ...sharedProps,
      type,
    };
    const linkProps = {
      ...(sharedProps as AnchorHTMLAttributes<HTMLAnchorElement>),
      href: to,
    };

    return isExternal ? (
      <a {...linkProps}>{content}</a>
    ) : to && LinkComponent !== undefined ? (
      <LinkComponent {...linkProps}>{content}</LinkComponent>
    ) : (
      <button {...buttonProps} ref={ref}>
        {content}
      </button>
    );
  }
);
