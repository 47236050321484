'use client';

import { useEffect, useState } from 'react';

export const useViewportHeight = () => {
  const [pageHeight, setPageHeight] = useState<number | null>(null);

  useEffect(() => {
    setPageHeight(window.innerHeight || document.documentElement.clientHeight);

    const handleResize = () => {
      setTimeout(() => {
        setPageHeight(window.innerHeight || document.documentElement.clientHeight);
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return pageHeight;
};
