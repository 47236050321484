export const getPathnameWithPartialParameters = ({
  partialParameters,
  pathname,
  searchParameters,
}: {
  partialParameters: Record<string, string>;
  pathname: string;
  searchParameters: URLSearchParams;
}) => {
  const parameters = new URLSearchParams(searchParameters);

  for (const partialParameter of Object.entries(partialParameters)) {
    const key = partialParameter[0];
    const value = partialParameter[1];

    if (value) {
      parameters.set(key, value);
    } else {
      parameters.delete(key);
    }
  }

  return `${pathname}${parameters.toString() ? '?' : ''}${parameters.toString()}`;
};
