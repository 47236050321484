'use client';

import classNames from 'classnames';
import { type AnchorHTMLAttributes, type ComponentType } from 'react';
import { Button, type Key, Label, Tag, TagGroup, TagList } from 'react-aria-components';

import { Close } from '../svgs';

import styles from './tags-list.module.scss';

export enum TagVariant {
  Default = 'default',
  Info = 'info',
}

export type TagsListProps = {
  LinkComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
  items: TagItem[];
  label?: string;
  onRemove?: (keys: Set<Key>) => void;
  variant?: TagVariant;
};

type TagItem = {
  id: number;
  name: string;
  url?: string;
};

export const TagsList = ({ LinkComponent, items, label, onRemove, variant }: TagsListProps) => {
  const tagClasses = classNames([styles.tag, variant === TagVariant.Info && styles['tag--info']]);

  return (
    <TagGroup className={styles.tags} onRemove={(keys) => onRemove?.(keys)}>
      <Label className={styles.tags__label}>{label}</Label>
      <TagList>
        {items.map((item) => (
          <Tag className={tagClasses} id={item.id} key={item.id} textValue={item.name}>
            <div className={styles['tag__content']}>
              {item.url && LinkComponent !== undefined ? (
                <LinkComponent href={item.url}>{item.name}</LinkComponent>
              ) : (
                <>
                  {item.name}
                  {onRemove && (
                    <Button className={styles['tag__close-button']} slot="remove">
                      <Close />
                    </Button>
                  )}
                </>
              )}
            </div>
          </Tag>
        ))}
      </TagList>
    </TagGroup>
  );
};
