'use client';

import {
  type DateValue,
  Button,
  Calendar,
  CalendarCell,
  CalendarGrid,
  DateInput,
  DatePicker as AdobeDatePicker,
  DateSegment,
  Dialog,
  Group,
  Heading,
  Label,
  Popover,
} from 'react-aria-components';

export const DatePicker = ({
  onChange,
  value,
}: {
  onChange: (value: DateValue) => void;
  /**
   * Value should be set as ISO string (e.g. new Date('2024-01-01T12:00:00').toISOString())
   */
  value?: DateValue | null;
}) => {
  const handleChange = (zonedDateTime: DateValue) => {
    onChange(zonedDateTime);
  };

  return (
    <AdobeDatePicker
      granularity="day"
      hideTimeZone
      onChange={handleChange}
      value={value ? value : null}
    >
      <Label>Date</Label>
      <Group>
        <DateInput>{(segment) => <DateSegment segment={segment} />}</DateInput>
        <Button>▼</Button>
      </Group>
      <Popover>
        <Dialog>
          <Calendar>
            <header>
              <Button slot="previous">◀</Button>
              <Heading />
              <Button slot="next">▶</Button>
            </header>
            <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
          </Calendar>
        </Dialog>
      </Popover>
    </AdobeDatePicker>
  );
};
