'use client';

import classnames from 'classnames';
import { type TransitionEvent, useEffect, useState } from 'react';

import { getAppleDeviceInfo } from '../../utils';

import { DockIcon, HomeScreenIcon, ShareIcon } from './icons';
import styles from './apple-prompt.module.scss';

type ApplePromptProps = {
  addHomeInstructionsLabelIos: string;
  addHomeInstructionsLabelSafari: string;
  bodyLabelIos: string;
  bodyLabelSafari: string;
  cancelLabel: string;
  onClose: () => void;
  shareInstructionsLabelIos: string;
  shareInstructionsLabelSafari: string;
  titleLabelIos: string;
  titleLabelSafari: string;
};

export const ApplePrompt = ({
  addHomeInstructionsLabelIos,
  addHomeInstructionsLabelSafari,
  bodyLabelIos,
  bodyLabelSafari,
  cancelLabel,
  onClose,
  shareInstructionsLabelIos,
  shareInstructionsLabelSafari,
  titleLabelIos,
  titleLabelSafari,
}: ApplePromptProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const { isIPhone, isIosThirteenAndUp, isSafariSeventeenAndUp } = getAppleDeviceInfo();
  const visibilityClass = isVisible ? styles['is-visible'] : styles['is-hidden'];
  const isModernStyle = isIosThirteenAndUp || isSafariSeventeenAndUp;
  const modernStyle = isModernStyle && styles['is-modern'];
  const safariStyle = isSafariSeventeenAndUp && styles['is-safari'];
  const AddHomeIcon = isIPhone ? HomeScreenIcon : DockIcon;
  const addHomeInstructionsLabel = isIPhone
    ? addHomeInstructionsLabelIos
    : addHomeInstructionsLabelSafari;
  const bodyLabel = isIPhone ? bodyLabelIos : bodyLabelSafari;
  const shareInstructionsLabel = isIPhone
    ? shareInstructionsLabelIos
    : shareInstructionsLabelSafari;
  const titleLabel = isIPhone ? titleLabelIos : titleLabelSafari;

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('no-scroll');
    }
  }, [isVisible]);

  const handleCancel = () => {
    document.body.classList.remove('no-scroll');
    setIsVisible(false);
    onClose();
  };

  const onTransitionOut = (event: TransitionEvent<HTMLDivElement>) => {
    if (!isVisible) {
      event.currentTarget.style.display = 'none';
    }
  };

  return (
    <>
      <div
        aria-label={cancelLabel}
        className={classnames(styles.overlay, visibilityClass, modernStyle)}
        onClick={handleCancel}
        onTransitionEnd={onTransitionOut}
        role="button"
      />
      <div
        aria-describedby={bodyLabel}
        aria-labelledby={titleLabel}
        className={classnames(styles.prompt, visibilityClass, modernStyle, safariStyle)}
        onTransitionEnd={onTransitionOut}
        role="dialog"
      >
        <div className={styles.header}>
          <p className={styles.title}>{titleLabel}</p>
          <button className={styles['cancel-btn']} onClick={handleCancel}>
            {cancelLabel}
          </button>
        </div>
        <div className={styles.body}>
          <div className={styles.description}>
            <p className={styles.paragraph}>{bodyLabel}</p>
          </div>
        </div>
        <div className={styles.instructions}>
          <div className={styles['instruction-step']}>
            <ShareIcon className={styles['share-icon']} modern={isModernStyle} />
            <p className={classnames(styles.paragraph, styles['paragraph--bold'])}>
              {shareInstructionsLabel}
            </p>
          </div>
          <div className={styles['instruction-step']}>
            <AddHomeIcon className={styles['home-icon']} modern={isModernStyle} />
            <p className={classnames(styles.paragraph, styles['paragraph--bold'])}>
              {addHomeInstructionsLabel}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
