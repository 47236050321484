import * as React from 'react';
import type { SVGProps } from 'react';
const SvgImpersonate = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M14 11q1.25 0 2.125-.875A2.9 2.9 0 0 0 17 8q0-1.25-.875-2.125A2.9 2.9 0 0 0 14 5q-1.25 0-2.125.875A2.9 2.9 0 0 0 11 8q0 1.25.875 2.125A2.9 2.9 0 0 0 14 11m-6 4.75a7.6 7.6 0 0 1 2.7-2.037A7.9 7.9 0 0 1 14 13q1.725 0 3.3.713A7.6 7.6 0 0 1 20 15.75V4H8zM8 18q-.824 0-1.412-.587A1.93 1.93 0 0 1 6 16V4q0-.824.588-1.412A1.93 1.93 0 0 1 8 2h12q.824 0 1.413.587Q22 3.176 22 4v12q0 .824-.587 1.413A1.93 1.93 0 0 1 20 18zm-4 4q-.824 0-1.412-.587A1.93 1.93 0 0 1 2 20V7q0-.424.288-.713A.97.97 0 0 1 3 6q.424 0 .712.287Q4 6.576 4 7v13h13q.424 0 .712.288.288.287.288.712 0 .424-.288.712A.97.97 0 0 1 17 22zM14 9a.97.97 0 0 1-.713-.287A.97.97 0 0 1 13 8q0-.424.287-.713A.97.97 0 0 1 14 7q.424 0 .713.287Q15 7.576 15 8q0 .424-.287.713A.97.97 0 0 1 14 9m-3.3 7h6.6a5.5 5.5 0 0 0-1.575-.75 6.1 6.1 0 0 0-3.45 0A5.5 5.5 0 0 0 10.7 16"
    />
  </svg>
);
export default SvgImpersonate;
