import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="facebook-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m3.394 8.182h-2.22c-2.665 0-3.603 1.311-3.603 3.515v1.623h-1.662v2.702h1.662v7.842h3.325v-7.842h2.219l.294-2.702h-2.513l.004-1.353c0-.705.069-1.082 1.107-1.082h1.387z"
    />
  </svg>
);
export default SvgFacebookIcon;
