import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPinterestIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="pinterest-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m.413 7.5c-4.807 0-7.231 3.469-7.231 6.36 0 1.752.66 3.31 2.072 3.891.232.095.44.003.507-.255l.028-.11.113-.452c.025-.1.048-.193.065-.255.068-.255.041-.345-.146-.568-.407-.483-.667-1.11-.667-1.997 0-2.573 1.912-4.876 4.981-4.876 2.718 0 4.21 1.67 4.21 3.902 0 2.936-1.291 5.414-3.207 5.414-1.06 0-1.851-.881-1.597-1.961.304-1.29.892-2.683.892-3.614 0-.833-.444-1.528-1.364-1.528-1.082 0-1.952 1.126-1.952 2.635 0 .961.323 1.61.323 1.61l-1.3 5.55c-.387 1.647-.059 3.665-.03 3.869.015.12.17.15.24.059.1-.132 1.387-1.733 1.826-3.332.124-.453.711-2.8.711-2.8.352.676 1.38 1.27 2.472 1.27 3.253 0 5.46-2.984 5.46-6.979 0-3.02-2.543-5.833-6.406-5.833"
    />
  </svg>
);
export default SvgPinterestIcon;
