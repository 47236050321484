export type ParsedToken = {
  MDRTId: string;
  alternativeSecurityIds?: Array<{
    identityProvider: string;
    value: string;
  }>;
  credentialId?: string;
  groups: string[];
  idp?: string;
  personId: number;
  sub: string;
};

export const parseJwtToken = (token: string): ParsedToken | undefined => {
  if (token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  }

  return undefined;
};
