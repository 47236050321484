/* eslint-disable id-length */
/* eslint-disable import/no-extraneous-dependencies */
'use client';

import {
  type ChartOptions,
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Tooltip,
  LogarithmicScale,
} from 'chart.js';
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

import {
  type DonationLevel,
  formatCurrency,
  getDonationAmounts,
  useCssVariable,
} from '@shared/utils';

import {
  type TickLinesPlugin,
  type DonorLevelLabelsPlugin,
  type HorizontalBackgroundPlugin,
  type NextClosestLevelPlugin,
  getCustomPlugins,
} from './get-custom-plugins';
import styles from './donation-chart.module.scss';

ChartJS.register(BarElement, CategoryScale, Tooltip, LogarithmicScale);

type DonationChartProps = {
  ariaLabel: string;
  donation?: number;
  donationLevels: DonationLevel[];
  nextClosestLevel: DonationLevel | null;
};

type CustomPlugins = {
  plugins: {
    donorLevelLabelsPlugin: DonorLevelLabelsPlugin;
    horizontalBackgroundPlugin: HorizontalBackgroundPlugin;
    nextClosestLevelPlugin: NextClosestLevelPlugin;
    tickLinesPlugin: TickLinesPlugin;
  };
};

export const DonationChart = ({
  ariaLabel,
  donation = 0,
  donationLevels,
  nextClosestLevel,
}: DonationChartProps) => {
  const scale = getDonationAmounts(donationLevels);
  const tickLength = 16;
  const { barPercentage: defaultBarPercentage, categoryPercentage: defaultCategoryPercentage } =
    ChartJS.defaults.datasets.bar;
  const customPlugins = getCustomPlugins();
  const minValue = scale[0] * 0.65;

  const [primaryFontFamily] = useCssVariable('--font-family-primary');
  const [tickLabelColor] = useCssVariable('--mdrt-color-text-on-dark-secondary');
  const [gridLinesColor] = useCssVariable('--temp-color-white-opacity-30');
  const [barFillColor] = useCssVariable('--mdrt-color-backgrounds-bg-secondary-fill');
  const [barBackgroundFillColor] = useCssVariable(
    '--mdrt-color-backgrounds-bg-neutral-hover-inverse'
  );

  const chartData = {
    datasets: [
      {
        data: [donation !== 0 && donation < scale[0] ? donation * 0.3 + minValue : donation],
      },
    ],
    labels: [''],
  };

  const chartOptions: ChartOptions<'bar'> & CustomPlugins = {
    datasets: {
      bar: {
        backgroundColor: barFillColor.propertyValue,
        barPercentage: 0.6,
        categoryPercentage: 0.8,
      },
    },
    indexAxis: 'y',
    layout: {
      autoPadding: false,
      padding: {
        bottom: 8,
        left: 0,
        right: 1,
        top: 35,
      },
    },
    maintainAspectRatio: false,
    plugins: {
      donorLevelLabelsPlugin: {
        color: tickLabelColor.propertyValue,
        donationLevels: donation > 0 ? donationLevels : null,
        font: `600 16px ${primaryFontFamily.propertyValue}`,
        lineHeight: 18,
        maxWidth: 120,
      },
      horizontalBackgroundPlugin: {
        defaultBarPercentage,
        defaultCategoryPercentage,
        fillColor: barBackgroundFillColor.propertyValue,
      },
      nextClosestLevelPlugin: {
        lineColor: barFillColor.propertyValue,
        nextClosestLevel,
        tickLength,
      },
      tickLinesPlugin: {
        lineColor: donation > 0 ? gridLinesColor.propertyValue : null,
        tickLength,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        afterTickToLabelConversion(context) {
          context.ticks = [];
          scale.map((value) => {
            return context.ticks.push({
              label: `USD ${formatCurrency(Number(value))}`,
              value,
            });
          });
        },
        border: {
          display: false,
        },
        clip: false,
        grid: {
          color: (context) => {
            const lastTick = context.chart.scales.x.ticks.length - 1;

            if (
              donation > 0 ||
              context.index === lastTick ||
              (nextClosestLevel !== null &&
                context.index === scale.indexOf(nextClosestLevel?.amount))
            ) {
              return 'transparent';
            }

            return gridLinesColor.propertyValue;
          },
          lineWidth: 1,
          tickLength,
          z: 1,
        },
        max: scale[scale.length - 1],
        min: minValue,
        position: 'bottom',
        ticks: {
          align: 'end',
          color: tickLabelColor.propertyValue,
          crossAlign: 'far',
          font: {
            family: primaryFontFamily.propertyValue,
            size: 14,
            weight: 600,
          },
          includeBounds: false,
          labelOffset: -8,
          padding: -8,
          z: 2,
        },
        type: 'logarithmic',
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <div className={styles['donation-chart']}>
      <div
        className={classNames(styles['donation-chart__wrapper'], {
          [styles[`donation-chart__wrapper--scale-${scale.length}`]]: scale.length > 3,
        })}
      >
        <Bar
          aria-label={ariaLabel}
          data={chartData}
          options={chartOptions}
          plugins={customPlugins}
          role="img"
        />
      </div>
    </div>
  );
};
