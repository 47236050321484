// eslint-disable-next-line @nx/enforce-module-boundaries
import { logException } from '@shared/utils';

export enum RedirectType {
  Permanent = 301,
  Temporary = 302,
}

export type OptimizelyRedirect = {
  Id: string | null;
  NewUrl: string | null;
  OldUrl: string | null;
  RedirectType: RedirectType;
  State: number;
  WildCardSkipAppend: boolean;
};

export const getOptimizelyRedirectNewUrl = async (
  oldUrl: string
): Promise<OptimizelyRedirect | null> => {
  try {
    const response = await fetch(
      `${process.env['OPTIMIZELY_SITE_URL']}/redirects?oldUrl=${encodeURIComponent(oldUrl)}`,
      {
        cache: 'force-cache',
        next: {
          tags: ['get-optimizely-redirects'],
        },
      }
    );

    return await response.json();
  } catch (error) {
    logException(error);
    return null;
  }
};
