/* eslint-disable id-length */
import { type Plugin } from 'chart.js';

import { type DonationLevel } from '@shared/utils';

export type HorizontalBackgroundPlugin = {
  defaultBarPercentage: number;
  defaultCategoryPercentage: number;
  fillColor: string;
};

export type NextClosestLevelPlugin = {
  lineColor: string;
  nextClosestLevel: DonationLevel | null;
  tickLength: number;
};

export type DonorLevelLabelsPlugin = {
  color: string;
  donationLevels: DonationLevel[] | null;
  font: string;
  lineHeight: number;
  maxWidth: number;
};

export type TickLinesPlugin = {
  lineColor: string | null;
  tickLength: number;
};

const wrapText = (context: CanvasRenderingContext2D, text: string, maxWidth: number) => {
  const words = text.split(' ');
  const lines = [];
  let currentLine = words[0];

  for (let index = 1; index < words.length; index++) {
    const word = words[index];
    const width = context.measureText(currentLine + ' ' + word).width;

    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
      lines.push(currentLine);
      currentLine = word;
    }
  }

  lines.push(currentLine);

  return lines;
};

export const getCustomPlugins = () => {
  const horizontalBackgroundPlugin: Plugin<'bar', HorizontalBackgroundPlugin> = {
    beforeDatasetDraw(chart, _, plugins) {
      const {
        chartArea: { height, left, width },
        ctx,
        data,
        scales: { y },
      } = chart;
      const { defaultBarPercentage, defaultCategoryPercentage } = plugins;

      const barPercentage = data.datasets[0].barPercentage || defaultBarPercentage;
      const categoryPercentage = data.datasets[0].categoryPercentage || defaultCategoryPercentage;
      const barThickness = height * barPercentage * categoryPercentage;

      ctx.save();
      ctx.fillStyle = plugins.fillColor;
      ctx.fillRect(left, y.getPixelForValue(0) - barThickness / 2, width, barThickness);
      ctx.restore();
    },
    id: 'horizontalBackgroundPlugin',
  };

  const nextClosestLevelPlugin: Plugin<'bar', NextClosestLevelPlugin> = {
    beforeDatasetDraw(chart, _, plugins) {
      const { tickLength, lineColor, nextClosestLevel } = plugins;
      const {
        chartArea: { bottom, top },
        ctx,
        scales: { x },
      } = chart;

      if (!nextClosestLevel) return;

      const nextClosestLevelPositionX = x.getPixelForValue(nextClosestLevel.amount);

      ctx.save();
      ctx.strokeStyle = lineColor;
      ctx.translate(0.5, 0.5);
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(nextClosestLevelPositionX, top - tickLength - 5);
      ctx.lineTo(nextClosestLevelPositionX, bottom + tickLength);
      ctx.stroke();
      ctx.restore();
    },
    id: 'nextClosestLevelPlugin',
  };

  const donorLevelLabelsPlugin: Plugin<'bar', DonorLevelLabelsPlugin> = {
    afterDatasetDraw(chart, _, plugins) {
      const {
        chartArea: { top },
        ctx,
        scales: { x },
      } = chart;
      const { color, font, donationLevels, maxWidth, lineHeight } = plugins;
      const { ticks } = x;

      if (!donationLevels) return;

      ctx.save();
      ctx.fillStyle = color;
      ctx.font = font;
      ctx.textAlign = 'right';

      for (const tick of ticks) {
        const donationLevelName =
          donationLevels.find((level) => level.amount === tick.value)?.name || '';
        const tickPositionX = x.getPixelForValue(tick.value);
        const wrappedText = wrapText(ctx, donationLevelName, maxWidth);

        for (const [index, line] of wrappedText.entries()) {
          ctx.fillText(
            line,
            tickPositionX - 8,
            top - 3 - (wrappedText.length - 1 - index) * lineHeight
          );
        }
      }

      ctx.restore();
    },
    id: 'donorLevelLabelsPlugin',
  };

  const tickLinesPlugin: Plugin<'bar', TickLinesPlugin> = {
    beforeDatasetDraw(chart, _, plugins) {
      const {
        chartArea: { bottom, top },
        ctx,
        scales: { x },
      } = chart;
      const { ticks } = x;
      const { tickLength, lineColor } = plugins;

      if (lineColor === null) return;

      ctx.save();
      ctx.strokeStyle = lineColor;
      ctx.translate(0.5, 0.5);
      ctx.lineWidth = 1;
      ctx.beginPath();

      for (const tick of ticks) {
        if (tick.value !== ticks[ticks.length - 1].value) {
          const tickPositionX = x.getPixelForValue(tick.value);
          ctx.moveTo(tickPositionX, top - tickLength - 5);
          ctx.lineTo(tickPositionX, bottom + tickLength);
        }
      }

      ctx.stroke();
      ctx.restore();
    },
    id: 'tickLinesPlugin',
  };

  return [
    horizontalBackgroundPlugin,
    nextClosestLevelPlugin,
    donorLevelLabelsPlugin,
    tickLinesPlugin,
  ];
};
