'use client';

import { type InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';

import { CheckboxInput } from '../checkbox-input/checkbox-input';

type CheckboxFieldProps = {
  dataTestId?: string;
  id?: string;
  isSwitch?: boolean;
  label: string;
  name: string;
  pushToRight?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckboxField = ({ label, name, ...restProps }: CheckboxFieldProps) => {
  const {
    field: { value: fieldValue, ...restFieldProps },
  } = useController({ name });

  return <CheckboxInput isChecked={fieldValue} label={label} {...restProps} {...restFieldProps} />;
};
