'use client';

import classNames from 'classnames';
import { type InputHTMLAttributes, forwardRef } from 'react';

import { type Transform } from '../field-controller';

import styles from './form-textarea.module.scss';

export type FormTextareaProps = {
  errorMessageId?: string;
  hasError?: boolean;
  isRequired?: boolean;
  maxLength?: number;
  transform?: Transform;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const FormTextarea = forwardRef<HTMLTextAreaElement, FormTextareaProps>(
  (
    {
      className,
      errorMessageId,
      hasError,
      id,
      isRequired,
      maxLength,
      onChange,
      transform,
      value,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <textarea
          aria-describedby={errorMessageId}
          aria-invalid={hasError}
          aria-required={isRequired}
          className={classNames(className, styles['form-textarea'], {
            [styles['form-textarea--has-error']]: hasError,
            [styles['form-textarea--has-value']]: Boolean(value),
          })}
          id={id}
          maxLength={maxLength}
          onChange={(event) => {
            onChange?.(transform ? transform.output(event) : event.target.value);
          }}
          ref={ref}
          value={transform ? transform.input(value) : value}
          {...rest}
        />
        <span className={styles['textarea-max-length']}>
          {value?.toString().length ?? 0}/{maxLength}
        </span>
      </>
    );
  }
);
