import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="instagram-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m.913 7.5h-1.826a39 39 0 0 0-2.18.045c-.798.037-1.343.163-1.82.349a3.7 3.7 0 0 0-1.328.865 3.7 3.7 0 0 0-.865 1.328c-.186.477-.312 1.022-.349 1.82-.03.662-.041.95-.044 2.18v1.826c.003 1.23.014 1.518.044 2.18.037.798.163 1.343.349 1.82.191.493.448.911.865 1.328s.835.674 1.328.865c.477.186 1.022.312 1.82.349.662.03.95.041 2.18.044l.783.001h1.043a39 39 0 0 0 2.18-.045c.798-.037 1.343-.163 1.82-.349a3.7 3.7 0 0 0 1.328-.865c.417-.417.674-.835.865-1.328.186-.477.312-1.022.349-1.82.03-.662.041-.95.044-2.18l.001-.783v-1.043a39 39 0 0 0-.045-2.18c-.037-.798-.163-1.343-.349-1.82a3.7 3.7 0 0 0-.865-1.328 3.7 3.7 0 0 0-1.328-.865c-.477-.186-1.022-.312-1.82-.349-.662-.03-.95-.041-2.18-.044m1.484 1.37.173.005.46.02c.732.033 1.129.156 1.393.258.35.136.6.299.863.561.262.263.425.513.56.863.103.264.226.661.259 1.392l.02.461.006.173c.011.378.016.804.017 1.698V15.7c-.001.894-.006 1.32-.017 1.698l-.006.173-.02.46c-.033.732-.156 1.129-.258 1.393-.136.35-.299.6-.561.863a2.3 2.3 0 0 1-.863.56c-.264.103-.661.226-1.392.259l-.461.02-.173.006c-.378.011-.804.016-1.698.017H14.3a58 58 0 0 1-1.698-.017l-.173-.006-.46-.02c-.732-.033-1.129-.156-1.393-.258-.35-.136-.6-.299-.863-.561a2.3 2.3 0 0 1-.56-.863c-.103-.264-.226-.661-.259-1.392l-.02-.461-.006-.173a58 58 0 0 1-.017-1.698V14.3c.001-.894.006-1.32.017-1.698l.006-.173.02-.46c.033-.732.156-1.129.258-1.393.136-.35.299-.6.561-.863.263-.262.513-.425.863-.56.264-.103.661-.226 1.392-.259l.461-.02.173-.006c.378-.011.804-.016 1.698-.017H15.7c.894.001 1.32.006 1.698.017m-2.382 2.29a3.854 3.854 0 1 0 0 7.71 3.854 3.854 0 0 0 0-7.71m0 1.353a2.502 2.502 0 1 1 0 5.004 2.502 2.502 0 0 1 0-5.004m3.973-2.424a.893.893 0 1 0 0 1.786.893.893 0 0 0 0-1.786"
    />
  </svg>
);
export default SvgInstagramIcon;
