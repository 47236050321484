import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSoundcloudIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="soundcloud-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M15 0c8.283 0 15 6.717 15 15s-6.717 15-15 15S0 23.283 0 15 6.717 0 15 0m1.156 8a.66.66 0 0 0-.656.667v10.666l.007.099a.66.66 0 0 0 .65.568h6.562l.186-.005C24.629 19.897 26 18.442 26 16.667c0-1.838-1.47-3.334-3.281-3.334q-.352.001-.684.073C21.74 10.376 19.215 8 16.156 8M5.875 15.385c-.483 0-.875.258-.875.577v3.461l.008.078c.058.282.424.499.867.499.483 0 .875-.258.875-.577v-3.461l-.008-.079c-.058-.281-.424-.498-.867-.498m7.875-6.462c-.483 0-.875.31-.875.692v9.693l.008.094c.058.337.424.598.867.598.483 0 .875-.31.875-.692V9.615l-.008-.094c-.058-.337-.424-.598-.867-.598m-2.625 2.77c-.483 0-.875.31-.875.692v6.923l.008.094c.058.337.424.598.867.598.483 0 .875-.31.875-.692v-6.923l-.008-.094c-.058-.338-.424-.599-.867-.599m-2.625 0c-.483 0-.875.31-.875.692v6.923l.008.094c.058.337.424.598.867.598.483 0 .875-.31.875-.692v-6.923l-.008-.094c-.058-.338-.424-.599-.867-.599"
    />
  </svg>
);
export default SvgSoundcloudIcon;
