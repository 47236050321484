import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLastPage = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#last_page_svg__a)">
      <path
        fill="currentColor"
        d="M4.658 6.175 8.483 10l-3.825 3.825L5.833 15l5-5-5-5zM13.333 5H15v10h-1.667z"
      />
    </g>
    <defs>
      <clipPath id="last_page_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLastPage;
