'use client';

import { type ReactNode } from 'react';

import { Button } from '../../../button';
import { Close } from '../../../svgs';

import styles from './web-prompt.module.scss';

type WebPromptProps = {
  bodyLabel: string;
  installBtnLabel: string;
  logo: ReactNode;
  onAccept: () => void;
  onClose: () => void;
};

export const WebPrompt = ({
  bodyLabel,
  installBtnLabel,
  logo,
  onAccept,
  onClose,
}: WebPromptProps) => {
  return (
    <div className={styles.prompt}>
      {logo}
      <p>{bodyLabel}</p>
      <Button className={styles['install-btn']} onClick={onAccept}>
        {installBtnLabel}
      </Button>
      <Button className={styles['close-btn']} onClick={onClose}>
        <Close />
      </Button>
    </div>
  );
};
