export const mapOptimizelyLocaleToIntlLocale = (locale: string) => {
  // this is needed because locale returned from Optimizely is invalid for html "lang" attribute
  if (locale.toLocaleLowerCase() === 'zh-cht') {
    return 'zh-hant';
  }

  if (locale.toLocaleLowerCase() === 'zh-cn') {
    return 'zh-hans';
  }

  return locale;
};
