'use client';

import { type RefObject, useEffect, useState } from 'react';

export const useCalculateLines = <T extends HTMLElement>(
  textRef: RefObject<T> | null | undefined
) => {
  const [lineCount, setLineCount] = useState(0);

  useEffect(() => {
    const currentTextElement = textRef?.current;

    const calculateLines = () => {
      if (!currentTextElement) return;

      const firstChildElement = currentTextElement.firstChild;
      if (!firstChildElement || !(firstChildElement instanceof Element)) return;

      const lineHeight = Number.parseInt(window.getComputedStyle(firstChildElement).lineHeight, 10);
      const height = currentTextElement.offsetHeight;
      const lines = Math.round(height / lineHeight);

      setLineCount(lines);
    };

    const resizeObserver = new ResizeObserver(calculateLines);

    calculateLines();

    if (currentTextElement) resizeObserver.observe(currentTextElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { lineCount };
};
