'use client';
import classNames from 'classnames';
import { type ChangeEvent } from 'react';
import { type RegisterOptions, useFormContext } from 'react-hook-form';

import { PICTURE_UPLOAD_ERROR_ID } from '@shared/utils';

import { ButtonVariant } from '../button';
import { ChipTheme } from '../chip';

import styles from './file-input.module.scss';

type FileInputProps = {
  ariaDescribedBy?: string;
  asChip?: boolean;
  dataTestId?: string;
  hasError: boolean;
  label?: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  rules: RegisterOptions;
  theme?: ChipTheme;
  variant?: ButtonVariant;
};

export const FileInput = ({
  ariaDescribedBy,
  asChip,
  dataTestId,
  hasError,
  label,
  name,
  onChange,
  rules,
  theme,
  variant,
}: FileInputProps) => {
  const { register } = useFormContext();

  return (
    label && (
      <label
        className={classNames({
          [styles['file-input-label']]: !asChip,
          [styles['file-input-label--secondary']]: variant === ButtonVariant.Secondary,
          [styles['file-input-label-chip']]: asChip,
          [styles['file-input-label-chip--theme-light']]: asChip && theme === ChipTheme.Light,
        })}
        htmlFor={name}
      >
        <input
          accept="image/*"
          aria-describedby={
            hasError ? `${PICTURE_UPLOAD_ERROR_ID} ${ariaDescribedBy}` : ariaDescribedBy
          }
          aria-invalid={hasError}
          className={styles['file-input']}
          data-testid={dataTestId}
          id={name}
          type="file"
          {...register(name, { ...rules, onChange })}
        />
        {label}
      </label>
    )
  );
};
