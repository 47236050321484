'use client';

import classnames from 'classnames';
import { type KeyboardEvent, type ReactElement, useRef, useState } from 'react';
import { useFocusWithin } from 'react-aria';
import {
  type MenuProps,
  Menu,
  MenuItem,
  type MenuItemProps,
  MenuTrigger,
  Popover,
  type PopoverProps,
} from 'react-aria-components';

import { KeyboardKey, useClickOutside } from '@shared/utils';

import { MoreIcon } from '../svgs';

import styles from './dropdown-menu.module.scss';

type DropdownMenuItem = MenuItemProps & {
  Icon?: ReactElement;
  selected?: boolean;
  withSeparatorAfter?: boolean;
  withSeparatorBefore?: boolean;
};

export type DropdownMenuProps<T = object> = {
  TriggerIcon?: ReactElement;
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEscapeKeyUp?: boolean;
  closeOnMenuBlur?: boolean;
  closeOnMenuItemClick?: boolean;
  containerPadding?: number;
  items: DropdownMenuItem[];
  menuClassName?: string;
  menuItemClassName?: string;
  menuPlacement?: PopoverProps['placement'];
  menuProps?: MenuProps<T>;
  selected?: boolean;
  shouldMenuFlip?: boolean;
  triggerClassName?: string;
  triggerDataTestId?: string;
  triggerLabel?: string;
};

export const DropdownMenu = <T extends object>({
  TriggerIcon,
  className,
  closeOnClickOutside = true,
  closeOnEscapeKeyUp = true,
  closeOnMenuBlur = true,
  closeOnMenuItemClick = true,
  containerPadding,
  items,
  menuClassName,
  menuItemClassName,
  menuPlacement,
  menuProps,
  selected,
  shouldMenuFlip = false,
  triggerClassName,
  triggerDataTestId,
  triggerLabel,
}: DropdownMenuProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);
  const wrapperRef = useClickOutside(() => {
    if (closeOnClickOutside) {
      setIsOpen(false);
    }
  });
  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: (event) =>
      closeOnMenuBlur && event.relatedTarget !== triggerRef.current && setIsOpen(false),
  });

  const handleMenuClick = () => {
    if (closeOnMenuItemClick) {
      // Preventing touch event bubbling with setTimeout: https://github.com/adobe/react-spectrum/issues/1513
      setTimeout(() => setIsOpen(false));
    }
  };

  const handleWrapperKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (closeOnEscapeKeyUp && event.key === KeyboardKey.Escape) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className={classnames(styles.wrapper, className)}
      onKeyUp={handleWrapperKeyUp}
      ref={wrapperRef}
    >
      <MenuTrigger isOpen={isOpen}>
        <button
          aria-expanded={isOpen}
          aria-label={triggerLabel}
          className={classnames(styles.trigger, triggerClassName, {
            [styles['trigger--selected']]: selected,
          })}
          data-testid={triggerDataTestId}
          onClick={() => setIsOpen(!isOpen)}
          ref={triggerRef}
          /*
          TabIndex is needed so that iOS Safari could get relatedTarget property
          from a menu blur event
          https://dev.azure.com/mdrt-experience/mdrt-experience/_workitems/edit/2920
          */
          tabIndex={0}
        >
          {TriggerIcon ?? (
            <div className={styles['trigger__more-icon-wrapper']}>
              <MoreIcon className={styles['trigger__more-icon']} />
            </div>
          )}
        </button>
        <Popover
          className={styles.popover}
          containerPadding={containerPadding || 24}
          isNonModal
          placement={menuPlacement}
          shouldFlip={shouldMenuFlip}
          triggerRef={triggerRef}
        >
          <div {...focusWithinProps}>
            <Menu
              className={classnames(styles.menu, menuClassName)}
              onAction={handleMenuClick}
              {...menuProps}
            >
              {items.map(
                ({
                  Icon,
                  children,
                  id,
                  selected: itemSelected,
                  withSeparatorAfter,
                  withSeparatorBefore,
                  ...rest
                }) => (
                  <MenuItem
                    className={classnames(
                      styles['menu-item'],
                      {
                        [styles['menu-item--with-separator-after']]: withSeparatorAfter,
                        [styles['menu-item--with-separator-before']]: withSeparatorBefore,
                        [styles['menu-item--selected']]: itemSelected,
                      },
                      menuItemClassName
                    )}
                    id={id}
                    key={id}
                    {...rest}
                  >
                    <>
                      {Icon && <div className={styles['menu-item-icon']}>{Icon}</div>}
                      {children}
                    </>
                  </MenuItem>
                )
              )}
            </Menu>
          </div>
        </Popover>
      </MenuTrigger>
    </div>
  );
};
