'use client';

import classNames from 'classnames';
import { forwardRef, type PropsWithChildren } from 'react';
import {
  Controller,
  type FieldError,
  type FieldValues,
  type RegisterOptions,
} from 'react-hook-form';
import ReactSelect, {
  components,
  type GroupBase,
  type MultiValueRemoveProps,
  type Props,
  type SelectInstance,
} from 'react-select';

import styles from '../field-controller/field-controller.module.scss';
import { FieldErrorMessage } from '../field-error-message';
import CloseIcon from '../svgs/close-icon';
import SvgErrorIcon from '../svgs/error-icon';

// eslint-disable-next-line import/no-unassigned-import
import './select.scss';

type BaseFieldProps = {
  dataTestId?: string;
  id?: string;
  label: string;
  name: string;
  optionalLabel?: string;

  // eslint-disable-next-line react/no-unused-prop-types
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
};

export type SelectOption<T = string | number> = Nullable<{
  label: string;
  value: T;
}>;

export type SelectFieldProps<
  Option = SelectOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = BaseFieldProps &
  Props<Option, IsMulti, Group> & {
    error?: FieldError;
    hint?: string;
    isRequired?: boolean;
    maximumSelectedOptions?: number;
    noOptionsLabel?: string;
    withoutController?: boolean;
  };

const FieldWrapper = ({
  children,
  dataTestId,
  error,
  hasOptionalLabel = true,
  hint,
  id,
  label,
  name,
  optionalLabel = 'optional',
}: PropsWithChildren<
  BaseFieldProps & { error: FieldError | undefined; hasOptionalLabel: boolean; hint?: string }
>) => {
  const fieldId = name ?? id;
  const errorMessageId = `${fieldId}-error`;

  return (
    <div className={styles.field} data-testid={dataTestId ?? fieldId}>
      {children}
      <label className={styles['field__label']} htmlFor={fieldId}>
        {label} {hasOptionalLabel && `(${optionalLabel})`}
      </label>
      {!error && hint && <p className={styles['field__hint']}>{hint}</p>}
      {error?.message && <SvgErrorIcon className={styles['field__error-icon']} />}
      {error?.message && <FieldErrorMessage id={errorMessageId} message={error.message} />}
    </div>
  );
};

const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <CloseIcon className="react-select__remove-selected-option-icon" />
  </components.MultiValueRemove>
);

const SelectFieldComponent = forwardRef<
  SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>,
  SelectFieldProps
>(
  (
    {
      error,
      hint,
      id,
      isDisabled,
      isMulti,
      isRequired = false,
      label,
      maximumSelectedOptions,
      name,
      noOptionsLabel,
      onChange,
      optionalLabel,
      options,
      value,
      ...restSelectFieldProps
    },
    ref
  ) => {
    const inputId = name ?? id;
    const errorMessageId = `${inputId}-error`;
    const hasError = Boolean(error);
    const hasValue = (Array.isArray(value) ? value.length : value) || (value && !isMulti);
    const optionsLength = Array.isArray(value) ? value.length : 0;

    return (
      <FieldWrapper
        error={error}
        hasOptionalLabel={!isRequired && !isDisabled}
        hint={hint}
        label={label}
        name={name}
        optionalLabel={optionalLabel}
      >
        <ReactSelect
          {...restSelectFieldProps}
          aria-describedby={errorMessageId}
          aria-invalid={hasError}
          aria-required={isRequired}
          className={classNames('react-select-container', {
            'react-select-container--has-error': hasError,
            'react-select-container--has-value': hasValue,
            'react-select-container--is-clearable':
              (Boolean(restSelectFieldProps.isClearable) || isMulti) && hasValue,
          })}
          classNamePrefix="react-select"
          classNames={{
            control: () =>
              classNames('react-select', {
                'react-select--has-error': hasError,
                'react-select--has-value': hasValue,
              }),
          }}
          components={{
            MultiValueRemove,
            Placeholder: () => null,
          }}
          inputId={inputId}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isOptionDisabled={
            maximumSelectedOptions && isMulti
              ? () => optionsLength >= maximumSelectedOptions
              : () => false
          }
          noOptionsMessage={() => noOptionsLabel}
          onChange={onChange}
          options={options}
          ref={ref}
          value={value}
        />
      </FieldWrapper>
    );
  }
);

export const SelectField = ({
  isRequired = false,
  name,
  onChange,
  rules,
  value: controlledValue,
  withoutController = false,
  ...restSelectFieldProps
}: SelectFieldProps) => {
  return withoutController ? (
    <SelectFieldComponent
      isRequired={isRequired}
      name={name}
      onChange={onChange}
      value={controlledValue}
      {...restSelectFieldProps}
    />
  ) : (
    <Controller
      name={name}
      render={({
        field: { onChange: fieldOnChange, value, ...restFieldProps },
        fieldState: { error },
      }) => {
        const isRequiredOrHasRequiredRule = isRequired || Boolean(rules?.required);

        return (
          <SelectFieldComponent
            error={error}
            isRequired={isRequiredOrHasRequiredRule}
            onChange={(newValue) => fieldOnChange(newValue)}
            value={value}
            {...restSelectFieldProps}
            {...restFieldProps}
          />
        );
      }}
      rules={rules}
    />
  );
};
