'use client';

import { cloneElement, type PropsWithChildren, type ReactElement, useRef } from 'react';
import {
  type AriaButtonProps,
  type AriaModalOverlayProps,
  Overlay,
  useModalOverlay,
  useOverlayTrigger,
} from 'react-aria';
import {
  type OverlayTriggerProps,
  type OverlayTriggerState,
  useOverlayTriggerState,
} from 'react-stately';

import styles from './modal.module.scss';

const Modal = ({
  children,
  state,
  ...props
}: PropsWithChildren<{ state: OverlayTriggerState } & AriaModalOverlayProps>) => {
  const ref = useRef(null);
  const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

  return (
    <Overlay>
      <div {...underlayProps} className={styles['overlay']}>
        <div {...modalProps} className={styles['container']} ref={ref}>
          {children}
        </div>
      </div>
    </Overlay>
  );
};

export const ModalTrigger = ({
  children,
  renderTrigger,
  ...props
}: {
  children: (handleClose: () => void) => ReactElement;
  renderTrigger?: (
    props: { handleModalOpen: () => void } & Omit<
      AriaButtonProps<'button'>,
      'onPress' | 'onPressChange' | 'onPressEnd' | 'onPressStart' | 'onPressUp'
    >
  ) => ReactElement;
} & OverlayTriggerProps) => {
  const state = useOverlayTriggerState(props);
  const {
    overlayProps,
    triggerProps: {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      onPress,
      onPressChange,
      onPressEnd,
      onPressStart,
      onPressUp,
      /* eslint-enable @typescript-eslint/no-unused-vars */
      ...restTriggerProps
    },
  } = useOverlayTrigger({ type: 'dialog' }, state);

  return (
    <>
      {renderTrigger &&
        renderTrigger({ ...restTriggerProps, handleModalOpen: () => state.setOpen(true) })}
      {state.isOpen && (
        <Modal state={state}>{cloneElement(children(state.close), overlayProps)}</Modal>
      )}
    </>
  );
};
