'use client';

import styles from './filed-error-message.module.scss';

type ErrorMessageProps = {
  id: string;
  message: string;
};

export const FieldErrorMessage = ({ id, message }: ErrorMessageProps) => (
  <p aria-live="polite" className={styles['field-error-message']} id={id} role="alert">
    {message}
  </p>
);
