'use client';

import { useEffect, useState } from 'react';

export const usePageScroll = (page: number) => {
  const [isInitialPage, setIsInitialPage] = useState(true);

  useEffect(() => {
    if (isInitialPage) {
      setIsInitialPage(false);
    } else {
      window.scrollTo(0, 0);
    }
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps
};
