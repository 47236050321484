'use client';

import { type AriaToastProps, useToast } from '@react-aria/toast';
import { type ToastState } from '@react-stately/toast';
import classnames from 'classnames';
import { useRef } from 'react';

import { AlertIcon, CancelIcon, InformationIcon, SuccessIcon } from '../svgs';

import { type ToastContent, ToastType } from './constants';
import styles from './toast.module.scss';

type ToastProps = {
  state: ToastState<ToastContent>;
} & AriaToastProps<ToastContent>;

const getToastIcon = (type?: ToastType) => {
  const iconClassName = styles['toast__icon'];

  switch (type) {
    case ToastType.ERROR:
      return <AlertIcon className={iconClassName} />;
    case ToastType.SUCCESS:
      return <SuccessIcon className={iconClassName} />;
    case ToastType.INFO:
    default:
      return <InformationIcon className={iconClassName} />;
  }
};

export const Toast = ({ state, ...props }: ToastProps) => {
  const ref = useRef(null);
  const { descriptionProps, toastProps } = useToast(props, state, ref);

  const { animation, content, key } = props.toast;
  const { closeButtonLabel, description, isCloseButtonVisible = true, type } = content;

  return (
    <div
      {...toastProps}
      className={classnames(styles.toast, {
        [styles['toast--without-close-button']]: !isCloseButtonVisible,
        [styles['toast--error']]: type === ToastType.ERROR,
        [styles['toast--success']]: type === ToastType.SUCCESS,
        [styles['toast--info']]: type === ToastType.INFO,
      })}
      data-animation={animation}
      onAnimationEnd={() => {
        if (animation === 'exiting') {
          state.remove(key);
        }
      }}
      ref={ref}
    >
      {getToastIcon(type)}
      <p className={styles['toast__message']} {...descriptionProps}>
        {description}
      </p>
      {isCloseButtonVisible && (
        <div className={styles['toast__actions-container']}>
          <div className={styles['toast__separator']} />
          <button
            aria-label={closeButtonLabel}
            className={styles['toast__close-button']}
            onClick={() => state.close(key)}
          >
            <CancelIcon />
          </button>
        </div>
      )}
    </div>
  );
};
