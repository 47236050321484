import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="#1C1C1C"
      d="M11 18a.97.97 0 0 1-.713-.288A.97.97 0 0 1 10 17a.97.97 0 0 1 .287-.712A.97.97 0 0 1 11 16h2a.97.97 0 0 1 .713.288Q14 16.575 14 17c0 .425-.096.52-.287.712A.97.97 0 0 1 13 18zm-4-5a.97.97 0 0 1-.713-.287A.97.97 0 0 1 6 12q0-.424.287-.713A.97.97 0 0 1 7 11h10a.97.97 0 0 1 .712.287Q18 11.575 18 12c0 .425-.096.52-.288.713A.97.97 0 0 1 17 13zM4 8a.97.97 0 0 1-.712-.287A.97.97 0 0 1 3 7a.97.97 0 0 1 .288-.713A.97.97 0 0 1 4 6h16a.97.97 0 0 1 .712.287Q21 6.575 21 7c0 .425-.096.52-.288.713A.97.97 0 0 1 20 8z"
    />
  </svg>
);
export default SvgFilterIcon;
