'use client';

import { useCallback, useState } from 'react';

type UseControlledProps<T> = {
  controlledValue: T | undefined;
  defaultValue: T;
};

export const useControlled = <T>({
  controlledValue,
  defaultValue,
}: UseControlledProps<T>): [T, (value: T) => void] => {
  const isControlled = controlledValue !== undefined;

  const [innerValue, setInnerValue] = useState(defaultValue);

  const value = isControlled ? controlledValue : innerValue;

  const setIfUncontrolled = useCallback(
    (newValue: T) => {
      if (!isControlled) {
        setInnerValue(newValue);
      }
    },
    [isControlled]
  );

  return [value, setIfUncontrolled];
};
