'use client';

import { type ChangeEvent, type ReactElement, cloneElement } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  Controller,
  useFormContext,
} from 'react-hook-form';

import { FieldErrorMessage } from '../field-error-message';
import SvgErrorIcon from '../svgs/error-icon';

import styles from './field-controller.module.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Transform<TElement = any> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: (value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  output: (event: ChangeEvent<TElement>) => any;
};

export type FieldControllerProps<T extends FieldValues> = {
  children: ReactElement;
  dataTestId?: string;
  hint?: string;
  id?: string;
  isRequired?: boolean;
  label: string;
  name: string;
  optionalLabel?: string;
  transform?: Transform;
  type?: string;
} & UseControllerProps<T>;

export const FieldController = <T extends FieldValues>({
  children,
  dataTestId,
  hint,
  id,
  isRequired = false,
  label,
  name,
  optionalLabel,
  rules,
  transform,
  type = 'text',
  ...rest
}: FieldControllerProps<T>) => {
  const { control } = useFormContext<T>();

  const isRequiredOrHasRequiredRule = isRequired || Boolean(rules?.required);
  const errorMessageId = `${name || id}-error`;
  const showOptionalLabel = !isRequiredOrHasRequiredRule && !children.props?.disabled;

  return (
    <div className={styles.field} data-testid={dataTestId ?? name ?? id}>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            {cloneElement(children, {
              ...field,
              errorMessageId,
              hasError: Boolean(error),
              id: name || id,
              isRequired: isRequiredOrHasRequiredRule,
              transform,
              type,
            })}
            <label className={styles['field__label']} htmlFor={name || id}>
              {label} {showOptionalLabel && optionalLabel && `(${optionalLabel})`}
            </label>
            {hint && <p className={styles['field__hint']}>{hint}</p>}
            {error?.message && <SvgErrorIcon className={styles['field__error-icon']} />}
            {error?.message && <FieldErrorMessage id={errorMessageId} message={error.message} />}
          </>
        )}
        rules={rules}
        {...rest}
      />
    </div>
  );
};
