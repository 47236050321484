import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevron = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#chevron_svg__a)">
      <path fill="currentColor" d="M8.333 5 7.158 6.175 10.975 10l-3.817 3.825L8.333 15l5-5z" />
    </g>
    <defs>
      <clipPath id="chevron_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChevron;
