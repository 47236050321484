import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPersonBookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 18q-1.4 0-2.675.438A7.3 7.3 0 0 0 7 19.75V20h10v-.25a7.3 7.3 0 0 0-2.325-1.313A8.2 8.2 0 0 0 12 18m0-2q1.725 0 3.225.525T18 18V4H6v14a10.3 10.3 0 0 1 2.775-1.475Q10.275 16 12 16m0-4q-.624 0-1.063-.438A1.45 1.45 0 0 1 10.5 10.5q0-.624.438-1.063A1.45 1.45 0 0 1 12 9q.624 0 1.063.438.437.437.437 1.062 0 .624-.438 1.063A1.45 1.45 0 0 1 12 12M6 22q-.824 0-1.412-.587A1.93 1.93 0 0 1 4 20V4q0-.824.588-1.413A1.93 1.93 0 0 1 6 2h12q.824 0 1.413.587.586.589.587 1.413v16q0 .824-.587 1.413A1.93 1.93 0 0 1 18 22zm6-8q1.45 0 2.475-1.025A3.37 3.37 0 0 0 15.5 10.5q0-1.45-1.025-2.475A3.37 3.37 0 0 0 12 7q-1.45 0-2.475 1.025A3.37 3.37 0 0 0 8.5 10.5q0 1.45 1.025 2.475A3.37 3.37 0 0 0 12 14"
    />
  </svg>
);
export default SvgPersonBookIcon;
