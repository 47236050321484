import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMagnifierIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="currentColor"
      d="m15.138 14.195-3.783-3.783a6 6 0 1 0-.943.943l3.783 3.783a.665.665 0 0 0 1.126-.601.67.67 0 0 0-.183-.342m-8.471-2.862a4.667 4.667 0 1 1 0-9.333 4.667 4.667 0 0 1 0 9.333"
    />
  </svg>
);
export default SvgMagnifierIcon;
