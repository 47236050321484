import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAdmin = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12 13q-1.474 0-2.488-1.013Q8.5 10.974 8.5 9.5t1.012-2.488Q10.526 6 12 6t2.488 1.012T15.5 9.5t-1.012 2.487Q13.475 13 12 13m0-2q.65 0 1.075-.425T13.5 9.5t-.425-1.075T12 8t-1.075.425T10.5 9.5t.425 1.075T12 11m0-6.875-6 2.25V11.1q0 1.35.375 2.625t1.025 2.4q1.05-.525 2.2-.825A9.5 9.5 0 0 1 12 15q1.25 0 2.4.3t2.2.825q.65-1.125 1.025-2.4T18 11.1V6.375zM12 17q-.9 0-1.75.2a9 9 0 0 0-1.625.55q.724.75 1.575 1.3.85.55 1.8.85a7.4 7.4 0 0 0 1.8-.85 9 9 0 0 0 1.575-1.3 9 9 0 0 0-1.625-.55A7.6 7.6 0 0 0 12 17m0 4.9a2 2 0 0 1-.625-.1Q8 20.675 6 17.637T4 11.1V6.375q0-.626.362-1.125.363-.5.938-.725l6-2.25a2.1 2.1 0 0 1 .7-.125q.35 0 .7.125l6 2.25q.575.225.938.725.362.5.362 1.125V11.1q0 3.5-2 6.537T12.625 21.8q-.15.05-.3.075T12 21.9"
    />
  </svg>
);
export default SvgAdmin;
