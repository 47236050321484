import * as Sentry from '@sentry/nextjs';

export const logErrorMessage = (errorMessage: string, identifiers?: string[]) => {
  Sentry?.captureMessage?.(errorMessage, identifiers ? undefined : { fingerprint: identifiers });
  // eslint-disable-next-line no-console
  console.error(errorMessage);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logException = (error: any) => {
  Sentry?.captureException?.(error);
  if (error instanceof Error) {
    // eslint-disable-next-line no-console
    console.error(`Catched exception: ${error.message} (${JSON.stringify(error)})`);
  } else {
    // eslint-disable-next-line no-console
    console.error(JSON.stringify(error));
  }
};
