import { type ButtonType } from '@shared/utils';

import { Button, ButtonVariant } from '../../../button';

import styles from './dialog-actions.module.scss';

export type PrimaryButtonProps = {
  onPrimaryButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonLabel: string;
  primaryButtonType?: ButtonType;
};

export type SecondaryButtonProps = {
  onSecondaryButtonClick?: () => void;
  secondaryButtonLabel?: string;
};

export type DialogActionsProps = PrimaryButtonProps & SecondaryButtonProps;

export const DialogActions = ({
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonDisabled,
  primaryButtonLabel,
  primaryButtonType,
  secondaryButtonLabel,
}: DialogActionsProps) => {
  return (
    <div className={styles['dialog-actions']}>
      {secondaryButtonLabel && onSecondaryButtonClick && (
        <Button onClick={onSecondaryButtonClick} variant={ButtonVariant.Secondary}>
          {secondaryButtonLabel}
        </Button>
      )}
      <Button
        disabled={primaryButtonDisabled}
        onClick={onPrimaryButtonClick && onPrimaryButtonClick}
        type={primaryButtonType}
      >
        {primaryButtonLabel}
      </Button>
    </div>
  );
};
