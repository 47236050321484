import { type ComponentPropsWithoutRef, type ElementType } from 'react';

type PolymorphicProps<E extends ElementType> = ComponentPropsWithoutRef<E> & {
  as?: E;
};

type RenderHTMLProps<T extends ElementType> = PolymorphicProps<T> & {
  html: string | null | undefined;
};

const defaultElement = 'div';

export const RenderHTML = <T extends ElementType = typeof defaultElement>({
  as,
  html,
  ...rest
}: RenderHTMLProps<T>) => {
  if (!html) {
    return null;
  }

  const Component = as || defaultElement;

  return (
    <Component
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
      {...rest}
    />
  );
};
