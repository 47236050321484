import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="youtube-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m4.468 10.227h-8.965c-.265.01-2.321.151-2.321 2.343v4.86s0 2.343 2.349 2.343h8.965c.266-.01 2.322-.151 2.322-2.343v-4.86s0-2.343-2.35-2.343m-6.285 2.165 4.464 2.615-4.464 2.613z"
    />
  </svg>
);
export default SvgYoutubeIcon;
