'use client';

import { logIn, type LogInProps } from '@shared/misc';
import { GTM_EVENT_LOGIN, GTM_EVENT_SEND_SUCCESS_LOGIN_KEY, sendGtmEvent } from '@shared/utils';

export const loginHandler = async (props: LogInProps) => {
  sendGtmEvent({
    event: GTM_EVENT_LOGIN,
    eventName: GTM_EVENT_LOGIN,
    label: GTM_EVENT_LOGIN,
  });

  const relativeReturnUrl = props.options?.redirectTo || window.location.pathname;
  const defaultAbsoluteReturnUrl = new URL(relativeReturnUrl, window.location.origin).href;

  await logIn({
    ...props,
    authorizationParameters: {
      referer: defaultAbsoluteReturnUrl,
      ...props.authorizationParameters,
    },
  }).then(() => window.sessionStorage.setItem(GTM_EVENT_SEND_SUCCESS_LOGIN_KEY, 'true'));
};
