import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWordpressIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="wordpress-icon-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m0 6c-4.963 0-9 4.037-9 9 0 4.962 4.037 9 9 9 4.962 0 9-4.038 9-9 0-4.963-4.038-9-9-9m.142 9.707 2.487 6.815a1 1 0 0 0 .058.111 8.07 8.07 0 0 1-4.973.13zm-7.534-4 3.86 10.575a8.09 8.09 0 0 1-3.988-10.27zm14.492-.59A8.05 8.05 0 0 1 23.092 15a8.09 8.09 0 0 1-4.024 6.994l2.472-7.146c.461-1.154.615-2.077.615-2.898q-.001-.447-.055-.832M15 6.909c2.106 0 4.025.806 5.464 2.125q-.052-.006-.105-.007c-.795 0-1.359.692-1.359 1.436 0 .667.385 1.231.795 1.898.308.539.667 1.231.667 2.232 0 .692-.266 1.496-.615 2.615l-.808 2.697-2.924-8.698c.487-.026.926-.077.926-.077.436-.052.385-.692-.051-.667 0 0-.583.046-1.222.076l-.322.014c-.214.008-.425.013-.613.013-.795 0-2.131-.103-2.131-.103-.437-.025-.488.641-.052.667 0 0 .413.051.85.077l1.26 3.454-1.771 5.31-2.947-8.764c.488-.026.926-.077.926-.077.436-.052.385-.692-.051-.667 0 0-.583.046-1.222.076l-.322.014c-.214.008-.425.013-.613.013-.152 0-.33-.004-.52-.01A8.08 8.08 0 0 1 15 6.908"
    />
  </svg>
);
export default SvgWordpressIcon;
