'use client';

import classNames from 'classnames';
import { forwardRef, type InputHTMLAttributes } from 'react';

import styles from './checkbox-input.module.scss';

type CheckboxInputProps = {
  dataTestId?: string;
  id?: string;
  isChecked: boolean;
  isSwitch?: boolean;
  label: string;
  name?: string;
  onChange?: () => void;
  pushToRight?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      dataTestId,
      id,
      isChecked,
      isSwitch = false,
      label,
      name,
      onChange,
      pushToRight,
      ...restProps
    },
    ref
  ) => {
    const fieldId = id ?? name;

    const renderSwitch = () => {
      return (
        <input
          {...restProps}
          checked={Boolean(isChecked)}
          className={classNames({
            [styles['switch__control']]: isSwitch,
            [styles['checkbox__control']]: !isSwitch,
          })}
          id={fieldId}
          onChange={onChange}
          ref={ref}
          type="checkbox"
          {...(isSwitch ? { role: 'switch' } : {})}
        />
      );
    };

    return (
      <div
        className={classNames({
          [styles['switch']]: isSwitch,
          [styles['checkbox']]: !isSwitch,
        })}
        data-testid={dataTestId ?? fieldId}
      >
        {!pushToRight && renderSwitch()}
        <label
          className={classNames({
            [styles['switch__label']]: isSwitch,
            [styles['switch__label--right']]: isSwitch && pushToRight,
            [styles['checkbox__label']]: !isSwitch,
            [styles['checkbox__label--right']]: !isSwitch && pushToRight,
          })}
          htmlFor={fieldId}
        >
          {label}
        </label>
        {pushToRight && renderSwitch()}
      </div>
    );
  }
);
