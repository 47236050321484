'use client';

import classNames from 'classnames';
import { type JSX, type PropsWithChildren, Fragment } from 'react';

import {
  GTM_EVENT_NAME_EXPAND_SECTION,
  GTM_EVENT_NAME_FAQS_VIEWED,
  sendGtmEvent,
  useControlled,
} from '@shared/utils';

import { Chevron, MinusIcon, PlusIcon } from '../svgs';

import styles from './accordion.module.scss';

const ExpandedIcon = () => (
  <span className={styles['toggle-icon']}>
    <MinusIcon />
  </span>
);

const CollapsedIcon = () => (
  <span className={styles['toggle-icon']}>
    <PlusIcon />
  </span>
);

export type AccordionProps = PropsWithChildren<{
  className?: string;
  headerLabel: string;
  headerLevel?: number;
  iconPosition?: 'left' | 'right';
  isExpanded?: boolean;
  isFaqAccordion?: boolean;
  onChange?: (isExpanded: boolean) => void;
  withPlusMinusIcon?: boolean;
}>;

export const Accordion = ({
  children,
  className,
  headerLabel,
  headerLevel,
  iconPosition = 'left',
  isExpanded: controlledIsExpanded,
  isFaqAccordion = false,
  onChange,
  withPlusMinusIcon = false,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useControlled({
    controlledValue: controlledIsExpanded,
    defaultValue: false,
  });

  const handleAccordionTriggerButtonClick = () => {
    if (!isExpanded) {
      sendGtmEvent({
        eventName: isFaqAccordion ? GTM_EVENT_NAME_FAQS_VIEWED : GTM_EVENT_NAME_EXPAND_SECTION,
        label: headerLabel,
      });
    }

    setIsExpanded(!isExpanded);
    if (onChange) {
      onChange(!isExpanded);
    }
  };

  const renderTriggerIcon = () => {
    if (withPlusMinusIcon) {
      return isExpanded ? <ExpandedIcon /> : <CollapsedIcon />;
    }

    return (
      <Chevron
        className={classNames(styles['accordion__trigger-icon'], {
          [styles['accordion__trigger-icon--is-open']]: isExpanded,
        })}
      />
    );
  };

  const HeaderWrapper = headerLevel ? (`h${headerLevel}` as keyof JSX.IntrinsicElements) : Fragment;

  return (
    <div
      className={classNames(styles.accordion, className, {
        [styles['accordion__plus-minus']]: withPlusMinusIcon,
      })}
    >
      <HeaderWrapper>
        <button
          aria-expanded={isExpanded}
          className={classNames(styles['accordion__trigger'], {
            [styles['accordion__trigger--spaced-out']]: iconPosition === 'right',
          })}
          data-testid="accordion-button"
          id="accordion-button"
          onClick={handleAccordionTriggerButtonClick}
          type="button"
        >
          {iconPosition === 'left' && renderTriggerIcon()}
          <span className={styles['accordion__label']}>{headerLabel}</span>
          {iconPosition === 'right' && renderTriggerIcon()}
        </button>
      </HeaderWrapper>
      {isExpanded && children}
    </div>
  );
};
