import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 5" {...props}>
    <path
      fill="currentColor"
      d="M2 4.5q-.824 0-1.412-.587A1.93 1.93 0 0 1 0 2.5q0-.825.588-1.412A1.93 1.93 0 0 1 2 .5q.824 0 1.413.587Q4 1.676 4 2.5q0 .824-.587 1.413A1.93 1.93 0 0 1 2 4.5m6 0q-.824 0-1.412-.587A1.93 1.93 0 0 1 6 2.5q0-.825.588-1.412A1.93 1.93 0 0 1 8 .5q.825 0 1.412.587Q10 1.676 10 2.5q0 .824-.588 1.413A1.93 1.93 0 0 1 8 4.5m6 0q-.825 0-1.412-.587A1.93 1.93 0 0 1 12 2.5q0-.825.588-1.412A1.93 1.93 0 0 1 14 .5q.825 0 1.412.587Q16 1.676 16 2.5q0 .824-.588 1.413A1.93 1.93 0 0 1 14 4.5"
    />
  </svg>
);
export default SvgMoreIcon;
