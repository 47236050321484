import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEmailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M19 4H5a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3m-1 2-6 5-6-5zm1 12H5a1 1 0 0 1-1-1V7l7.4 5.8a1 1 0 0 0 1.2 0L20 7v10a1 1 0 0 1-1 1"
    />
  </svg>
);
export default SvgEmailIcon;
