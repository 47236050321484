import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClipboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M12.107 19.036q-1.468 1.467-3.536 1.467t-3.535-1.468T3.568 15.5t1.468-3.536l2.12-2.12q.302-.301.708-.301.407 0 .707.3t.3.707-.3.707L6.45 13.38a2.9 2.9 0 0 0-.884 2.121q0 1.238.884 2.121.883.884 2.121.884t2.121-.884l2.122-2.121q.3-.3.707-.3t.707.3.3.707-.3.707zm-1.415-4.243q-.3.3-.707.3a.97.97 0 0 1-.707-.3q-.3-.3-.3-.707t.3-.707l4.243-4.243q.3-.3.707-.3t.707.3q.3.3.3.707t-.3.707zm6.364-.707q-.3.3-.707.3a.97.97 0 0 1-.707-.3q-.3-.3-.3-.707t.3-.707l2.122-2.122q.883-.883.883-2.121t-.883-2.121a2.9 2.9 0 0 0-2.122-.884q-1.237 0-2.121.884l-2.122 2.12q-.3.301-.707.301a.97.97 0 0 1-.707-.3q-.3-.3-.3-.707t.3-.707l2.122-2.122q1.467-1.467 3.535-1.467t3.536 1.467 1.467 3.536-1.467 3.535z"
    />
  </svg>
);
export default SvgClipboardIcon;
