import Skeleton from 'react-loading-skeleton';

import styles from './hash-tabs-navigation-skeleton.module.scss';

export const TabPanelSkeleton = () => {
  return (
    <div className={styles['tab-panel-skeleton']}>
      <Skeleton className={styles['tab-panel-skeleton__heading']} />
      <Skeleton className={styles['tab-panel-skeleton__text']} count={1.8} />
      <div className={styles['tab-panel-skeleton__links']}>
        <Skeleton className={styles['tab-panel-skeleton__link']} />
        <Skeleton className={styles['tab-panel-skeleton__link']} />
      </div>
      <Skeleton className={styles['tab-panel-skeleton__text']} count={1.5} />
      <Skeleton className={styles['tab-panel-skeleton__text']} count={2} />
      <div className={styles['tab-panel-skeleton__button-container']}>
        <Skeleton className={styles['tab-panel-skeleton__button']} />
      </div>
    </div>
  );
};

export const TabsNavigationSkeleton = () => {
  return (
    <div className={styles['tabs-navigation-skeleton']}>
      <div className={styles['tabs-navigation-skeleton__menu-button-container']}>
        <Skeleton className={styles['tabs-navigation-skeleton__menu-button']} />
      </div>
      <div className={styles['tabs-navigation-skeleton__list']}>
        {[1, 2, 3, 4, 5, 6, 7].map((key) => (
          <Skeleton className={styles['tabs-navigation-skeleton__skeleton-item']} key={key} />
        ))}
      </div>
    </div>
  );
};

export const HashTabsNavigationSkeleton = () => {
  return (
    <div className={styles['hash-tabs-navigation-skeleton']}>
      <TabsNavigationSkeleton />
      <TabPanelSkeleton />
    </div>
  );
};
