import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.707 19.207a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 1 1 1.414 1.414L7.414 11.5H19a1 1 0 1 1 0 2H7.414l4.293 4.293a1 1 0 0 1 0 1.414"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowIcon;
