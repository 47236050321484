'use client';

import { type AriaToastRegionProps, useToastRegion } from '@react-aria/toast';
import { type ToastState } from '@react-stately/toast';
import { useRef } from 'react';

import { type ToastContent } from '../../constants';
import { Toast } from '../../toast';

import styles from './toast-region.module.scss';

type ToastRegionProps = {
  state: ToastState<ToastContent>;
} & AriaToastRegionProps;

export const ToastRegion = ({ state, ...props }: ToastRegionProps) => {
  const ref = useRef(null);
  const { regionProps } = useToastRegion(props, state, ref);

  return (
    <div {...regionProps} className={styles['toast-region']} ref={ref}>
      {state.visibleToasts.map((toast) => (
        <Toast key={toast.key} state={state} toast={toast} />
      ))}
    </div>
  );
};
