import { IntlMessageFormat } from 'intl-messageformat';
import { useLocale } from 'next-intl';
import { getLocale } from 'next-intl/server';

import { mapOptimizelyLocaleToIntlLocale } from './map-optimizely-locale-to-intl-locale';

export const formatTranslationString = async (
  message: string,
  values: Record<string, string | number | boolean | null | undefined | Date> | undefined
) => {
  const intlLocale = mapOptimizelyLocaleToIntlLocale(await getLocale());

  try {
    return new IntlMessageFormat(message, intlLocale ? intlLocale : 'en-US').format<string>(
      values
    ) as string;
  } catch {
    return message;
  }
};

export const formatTranslationStringOnClient = (
  message: string,
  values: Record<string, string | number | boolean | null | undefined | Date> | undefined
) => {
  const optimizelyLocale = useLocale();

  const intlLocale = mapOptimizelyLocaleToIntlLocale(optimizelyLocale);

  try {
    return new IntlMessageFormat(message, intlLocale ? intlLocale : 'en-US').format<string>(
      values
    ) as string;
  } catch {
    return message;
  }
};
