'use client';

import classNames from 'classnames';
import { type MutableRefObject, type OutputHTMLAttributes, useRef, useState } from 'react';
import {
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria';
import { type SliderState, useSliderState } from 'react-stately';

import { getSliderColors } from './helpers';
import styles from './slider.module.scss';

const Thumb = ({
  ariaLabelledBy,
  index,
  name,
  outputProps,
  state,
  trackRef,
}: {
  ariaLabelledBy?: string;
  index: number;
  name: string;
  outputProps: OutputHTMLAttributes<HTMLOutputElement>;
  state: SliderState;
  trackRef: MutableRefObject<null>;
}) => {
  const inputRef = useRef(null);
  const { inputProps, thumbProps } = useSliderThumb(
    {
      index,
      inputRef,
      name,
      trackRef,
    },
    state
  );

  const sliderValue = state.values[0];
  const sliderStyles = getSliderColors((sliderValue / 10) * 100);

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      {...thumbProps}
      className={classNames(styles['slider__thumb'], {
        [styles['slider__thumb--is-dragging']]: state.isThumbDragging(index),
        [styles['slider__thumb--is-focused']]: isFocusVisible,
      })}
      style={{
        ...thumbProps.style,
        background: sliderStyles.thumbColor,
      }}
    >
      <output
        {...outputProps}
        aria-hidden
        className={classNames(styles['slider__output'], {
          [styles['slider__output--light']]: sliderValue > 7,
        })}
      >
        {state.getThumbValueLabel(0)}
      </output>
      <VisuallyHidden>
        <input
          aria-labelledby={ariaLabelledBy}
          ref={inputRef}
          {...mergeProps(inputProps, focusProps)}
        />
      </VisuallyHidden>
    </div>
  );
};

export const Slider = ({
  ariaLabelledBy,
  defaultValue,
  name,
  onChangeHandler,
}: {
  ariaLabelledBy?: string;
  defaultValue: number;
  hasVisuallyHiddenLabel?: boolean;
  label?: string;
  name: string;
  onChangeHandler: (value: number) => void;
}) => {
  const trackRef = useRef(null);
  const numberFormatter = useNumberFormatter({});

  const [sliderStyles, setSliderStyles] = useState(getSliderColors((defaultValue / 10) * 100));
  const handleInputChange = (value: number) => {
    setSliderStyles(getSliderColors((value / 10) * 100));
  };

  const sliderProps = {
    'aria-labelledby': ariaLabelledBy,
    defaultValue,
    maxValue: 10,
    minValue: 0,
    onChange: (value: number) => handleInputChange(value),
    onChangeEnd: (value: number) => {
      onChangeHandler(value);
    },
    step: 1,
  };

  const state = useSliderState({
    ...sliderProps,
    numberFormatter,
  });
  const { groupProps, outputProps, trackProps } = useSlider(
    {
      ...sliderProps,
    },
    state,
    trackRef
  );

  const sliderValue = state.values[0];

  return (
    <div className={styles['slider-container']}>
      <div {...groupProps} className={styles.slider}>
        <div {...trackProps} ref={trackRef}>
          <Thumb
            ariaLabelledBy={groupProps['aria-labelledby']}
            index={0}
            name={name}
            outputProps={outputProps}
            state={state}
            trackRef={trackRef}
          />
          <div
            className={classNames(styles['slider__track-style'], {
              [styles[`slider__track-style--${sliderValue}`]]: sliderValue,
            })}
            style={{ background: sliderStyles.trackGradient }}
          />
        </div>
      </div>
    </div>
  );
};
