import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAudioIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g fill="currentColor" clipPath="url(#audio-icon_svg__clip0_1837_2627)">
      <path
        fillRule="evenodd"
        d="M11.667 3.349v13.309c0 .168-.047.258-.134.402a.917.917 0 0 1-1.316.268l-5.484-3.994H2.5a1.667 1.667 0 0 1-1.667-1.667V8.334A1.667 1.667 0 0 1 2.5 6.667h2.233l5.484-3.992a.917.917 0 0 1 1.315.268c.088.144.135.237.135.406M10 4.954 5.702 8.022a1.67 1.67 0 0 1-.969.312H2.5v3.333h2.233c.348 0 .686.108.969.31L10 15.047z"
        clipRule="evenodd"
      />
      <path d="M13.333 7.5A.833.833 0 0 1 15 7.5v5a.833.833 0 0 1-1.667 0zM16.667 5.833a.833.833 0 0 1 1.666 0v8.334a.833.833 0 0 1-1.666 0z" />
    </g>
  </svg>
);
export default SvgAudioIcon;
