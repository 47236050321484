import * as React from 'react';
import type { SVGProps } from 'react';
const SvgVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <g fill="currentColor">
      <path d="M15.833 13.333H4.167v-10h11.666m0-1.666H4.167c-.442 0-.938.104-1.25.416-.313.313-.417.808-.417 1.25v10c0 .442.104.938.417 1.25.312.313.808.417 1.25.417h11.666c.442 0 .938-.104 1.25-.417.313-.312.417-.808.417-1.25v-10c0-.925-.75-1.666-1.667-1.666M9.01 5.54a.417.417 0 0 0-.677.326V10.8c0 .349.404.543.677.325l3.083-2.466a.417.417 0 0 0 0-.651zM4.167 17.5c0-.46.373-.834.833-.834h10a.833.833 0 0 1 0 1.667H5a.833.833 0 0 1-.833-.833" />
    </g>
  </svg>
);
export default SvgVideoIcon;
