'use client';

import { type Node } from '@react-types/shared';
import classNames from 'classnames';
import Link from 'next/link';
import { type ReactNode, useEffect, useRef, useState } from 'react';
import {
  type AriaTabListProps,
  type AriaTabPanelProps,
  type AriaTabProps,
  useFocusVisible,
  useTab,
  useTabList,
  useTabPanel,
} from 'react-aria';
import Skeleton from 'react-loading-skeleton';
import { type TabListState, useTabListState } from 'react-stately';

import { Caret, SuccessIcon } from '../svgs';

import { TabPanelSkeleton, TabsNavigationSkeleton } from './components';
import styles from './hash-tabs-navigation.module.scss';

type TabItemProps = AriaTabProps & {
  formComponent: ReactNode | null;
  hashUrl: string;
  key: string;
  relativePath: string | null | undefined;
  tabTitle: string;
};

export type HashTabsNavigationItems = Iterable<TabItemProps> | undefined;

const Tab = ({
  clickHandler,
  item,
  state,
}: {
  clickHandler: () => void;
  item: Node<TabItemProps>;
  state: TabListState<TabItemProps>;
}) => {
  const { key, value } = item;

  const { isFocusVisible } = useFocusVisible();
  const ref = useRef<HTMLAnchorElement | null>(null);
  const { isSelected, tabProps } = useTab({ key }, state, ref);

  const url = `${value?.relativePath}${value?.hashUrl}`;

  return (
    <Link
      className={classNames(styles['navigation-tab'], {
        [styles['navigation-tab--active']]: isSelected,
        [styles['navigation-tab--focus-visible']]: isFocusVisible,
      })}
      data-testid="tab-item"
      {...tabProps}
      href={url}
      key={value?.key}
      onClick={clickHandler}
      onTouchEnd={clickHandler}
      ref={ref}
      scroll={false}
    >
      {value?.tabTitle}
      {isSelected && (
        <SuccessIcon className={styles['navigation-tab__check-icon']} key={value?.key} />
      )}
    </Link>
  );
};

const TabPanel = ({
  state,
  ...props
}: AriaTabPanelProps & { state: TabListState<TabItemProps> }) => {
  const ref = useRef(null);
  const { tabPanelProps } = useTabPanel(props, state, ref);

  return (
    <div
      className={styles['settings-section']}
      data-testid="settings-tab-content"
      {...tabPanelProps}
      ref={ref}
      tabIndex={0}
    >
      {state.selectedItem ? state.selectedItem.props.children : <TabPanelSkeleton />}
    </div>
  );
};

export const HashTabsNavigation = (props: AriaTabListProps<TabItemProps>) => {
  const [navigationExpanded, setNavigationExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(window.location.hash || '');

  const state = useTabListState({
    onSelectionChange: (key) => {
      window.location.hash = `#${key}`;

      setTimeout(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      }, 0);
    },
    selectedKey: selectedTab ? selectedTab.replace('#', '') : '',
    ...props,
  });
  const ref = useRef(null);
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const { tabListProps } = useTabList(props, state, ref);

  useEffect(() => {
    const handleHashChange = () => {
      setSelectedTab(window.location.hash || '');
    };

    window.addEventListener('hashchange', handleHashChange);
    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <div className={styles['navigation-tabs-wrapper']}>
      <div
        className={classNames(styles['navigation-tabs-container'], {
          [styles['navigation-tabs-container--expanded']]: navigationExpanded,
        })}
        data-testid="navigation-tabs-container"
      >
        {state?.selectedItem?.value?.tabTitle ? (
          <button
            aria-expanded={navigationExpanded}
            className={styles['navigation-tabs-container__expand-button']}
            onClick={() => setNavigationExpanded((previous) => !previous)}
          >
            <Caret className={styles['navigation-tabs-container__expand-button-caret']} />
            {state?.selectedItem?.value?.tabTitle}
          </button>
        ) : (
          <div className={styles['navigation-tabs-container__expand-button--skeleton']}>
            <Skeleton height={47} />
          </div>
        )}
        <div
          className={styles['navigation-tabs-container__tabs-list']}
          {...tabListProps}
          data-testid="navigation-tabs-list"
          ref={ref}
        >
          {state.selectedItem ? (
            [...state.collection].map((item) => {
              return (
                <Tab
                  clickHandler={() => setNavigationExpanded(false)}
                  item={item}
                  key={item.key}
                  state={state}
                />
              );
            })
          ) : (
            <TabsNavigationSkeleton />
          )}
        </div>
      </div>
      <div className={styles['navigation-tabs-content']} ref={contentContainerRef}>
        <TabPanel key={state.selectedItem?.key} state={state} />
      </div>
    </div>
  );
};
