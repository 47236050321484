'use client';

import { useEffect, useState } from 'react';

import { type BeforeInstallPromptEvent } from '../../../../globals';

import { useShouldShowInstallPrompt } from './use-should-show-install-prompt';

export const useWebInstallPrompt = (): [
  BeforeInstallPromptEvent | null,
  () => void,
  () => void
] => {
  const [installPromptEvent, setInstallPromptEvent] = useState<BeforeInstallPromptEvent | null>(
    null
  );
  const [userShouldBePromptedToInstall, hideInstallPrompt] =
    useShouldShowInstallPrompt('webInstallPromptedAt');

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
      event.preventDefault();

      if (userShouldBePromptedToInstall) {
        setInstallPromptEvent(event);
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, [userShouldBePromptedToInstall]);

  const handleInstallDeclined = () => {
    hideInstallPrompt();
    setInstallPromptEvent(null);
  };

  const handleInstallAccepted = async () => {
    if (!installPromptEvent) return;

    await installPromptEvent.prompt();

    const userChoice = await installPromptEvent.userChoice;

    if (userChoice?.outcome !== 'accepted') {
      hideInstallPrompt();
    }

    setInstallPromptEvent(null);
  };

  return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};
