import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInformationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M13 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m0 8a1 1 0 1 1-2 0v-5a1 1 0 1 1 2 0zm-1 6c5.53 0 10-4.5 10-10a10 10 0 1 0-10 10m0-18a8 8 0 1 1 0 16 8 8 0 0 1 0-16"
    />
  </svg>
);
export default SvgInformationIcon;
