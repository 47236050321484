'use client';

import classNames from 'classnames';
import {
  useRef,
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ComponentType,
  type PropsWithChildren,
  type ReactElement,
  Children,
} from 'react';

import { useCalculateLines } from '@shared/utils';

import { Badge } from '../badge';
import { RenderHTML } from '../render-html';

import { Card } from './card';
import styles from './carousel-card.module.scss';

export type LocationAndDate = {
  endDate: string;
  location: string;
  startDate: string;
};

export type CarouselCardProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLAnchorElement> & {
    ImageComponent?: ReactElement;
    LinkComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
    actionLabel?: string;
    badgeTitle?: string;
    description?: string;
    headline?: string;
    isHighlighted?: boolean;
    locationAndDate?: LocationAndDate[];
    target: string;
    to?: string;
  };

export const CarouselCard = ({
  ImageComponent,
  LinkComponent,
  actionLabel,
  badgeTitle,
  children,
  description,
  headline,
  isHighlighted = false,
  locationAndDate,
  onClick,
  target,
  to,
  ...props
}: PropsWithChildren<CarouselCardProps>) => {
  const childrenArray = Children.toArray(children);
  const textRef = useRef(null);
  const { lineCount } = useCalculateLines(textRef);
  const PARENT_CLASSNAME = 'carousel-card';
  const content = (
    <Card
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--highlighted`]]: isHighlighted,
      })}
      isClickable
    >
      {badgeTitle && <Badge className={styles[`${PARENT_CLASSNAME}__badge`]}>{badgeTitle}</Badge>}
      {ImageComponent && (
        <div>
          {/* eslint-disable-next-line react/jsx-pascal-case */}
          <ImageComponent.type
            {...ImageComponent.props}
            className={classNames([
              styles[`${PARENT_CLASSNAME}__image`],
              ImageComponent.props?.className,
            ])}
          />
        </div>
      )}
      <div className={styles[`${PARENT_CLASSNAME}__content`]}>
        <h2 className={styles[`${PARENT_CLASSNAME}__content-headline`]}>{headline}</h2>
        <RenderHTML
          className={classNames(styles[`${PARENT_CLASSNAME}__content-description`], {
            [styles[`${PARENT_CLASSNAME}__content-description--line-clamp-3`]]: lineCount > 2,
            [styles[`${PARENT_CLASSNAME}__content-description--line-clamp-2`]]: lineCount > 3,
            [styles[`${PARENT_CLASSNAME}__content-description--line-clamp-no-children`]]:
              childrenArray.length === 0,
          })}
          html={description}
        />
        {children && <div ref={textRef}>{children}</div>}
      </div>

      <div className={styles[`${PARENT_CLASSNAME}__footer`]}>
        <span className={styles[`${PARENT_CLASSNAME}__footer-action`]}>{actionLabel}</span>
      </div>
    </Card>
  );

  return to && LinkComponent !== undefined ? (
    <LinkComponent
      className={styles[`${PARENT_CLASSNAME}__action`]}
      href={to}
      target={target}
      {...props}
    >
      {content}
    </LinkComponent>
  ) : (
    <button
      className={styles[`${PARENT_CLASSNAME}__action`]}
      onClick={onClick}
      type="button"
      {...props}
    >
      {content}
    </button>
  );
};
