import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFileCheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5 19V5h7v7h7v1c.7 0 1.37.13 2 .35V9l-6-6H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h8.35c-.22-.63-.35-1.3-.35-2zm9-14 5 5h-5zm7.65 10.79a.903.903 0 0 1-.046 1.2l-3.116 3.242a1 1 0 0 1-1.458-.017l-1.488-1.623a.838.838 0 0 1 1.21-1.16l.998.998 2.547-2.688a.903.903 0 0 1 1.353.048"
    />
  </svg>
);
export default SvgFileCheckIcon;
