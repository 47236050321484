import styles from './input-preview.module.scss';

type InputPreviewProps = {
  label?: string;
  value?: string | string[];
};

export const InputPreview = ({ label, value }: InputPreviewProps) => {
  return (
    <>
      <dt className={styles['input-preview__label']}>{label}</dt>
      <dd className={styles['input-preview__value']}>{value}</dd>
    </>
  );
};
