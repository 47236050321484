import * as React from 'react';
import type { SVGProps } from 'react';
const SvgProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40" {...props}>
    <path
      fill="currentColor"
      d="M20 5A15.017 15.017 0 0 0 5 20c0 4.008 1.561 7.774 4.395 10.605A14.9 14.9 0 0 0 20 35a15.017 15.017 0 0 0 15-15A15.017 15.017 0 0 0 20 5m0 2.139A12.876 12.876 0 0 1 32.861 20a12.8 12.8 0 0 1-3.434 8.712 12.5 12.5 0 0 0-5.483-3.75 6.41 6.41 0 0 0 2.373-4.975 6.41 6.41 0 0 0-6.445-6.39 6.41 6.41 0 0 0-6.39 6.442c0 1.986.932 3.742 2.36 4.914a12.5 12.5 0 0 0-5.401 3.631A12.76 12.76 0 0 1 7.139 20 12.876 12.876 0 0 1 20 7.139m-.075 8.597c2.346 0 4.252 1.907 4.252 4.302a4.25 4.25 0 0 1-4.113 4.24c-.062 0-.122-.014-.184-.014-.053 0-.105.011-.158.013a4.264 4.264 0 0 1-4.098-4.29c0-2.344 1.906-4.251 4.301-4.251M19.72 26.41c.051.003.103.017.154.017h.051c.047 0 .086-.014.133-.017a10.35 10.35 0 0 1 7.79 3.747A12.75 12.75 0 0 1 20 32.861a12.76 12.76 0 0 1-8.003-2.801 10.37 10.37 0 0 1 7.723-3.649"
    />
  </svg>
);
export default SvgProfileIcon;
