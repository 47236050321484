'use client';

import { type ReactNode } from 'react';

import { ApplePrompt, WebPrompt } from './components';
import { useAppleInstallPrompt, useWebInstallPrompt } from './hooks';
import styles from './install-pwa-prompt.module.scss';

type InstallPWAPromptProps = {
  appleCancelLabel: string;
  iosAddHomeInstructionsLabel: string;
  iosBodyLabel: string;
  iosShareInstructionsLabel: string;
  iosTitleLabel: string;
  safariAddHomeInstructionsLabel: string;
  safariBodyLabel: string;
  safariShareInstructionsLabel: string;
  safariTitleLabel: string;
  webBodyLabel: string;
  webInstallBtnLabel: string;
  webLogo: ReactNode;
};

export const InstallPWAPrompt = ({
  appleCancelLabel,
  iosAddHomeInstructionsLabel,
  iosBodyLabel,
  iosShareInstructionsLabel,
  iosTitleLabel,
  safariAddHomeInstructionsLabel,
  safariBodyLabel,
  safariShareInstructionsLabel,
  safariTitleLabel,
  webBodyLabel,
  webInstallBtnLabel,
  webLogo,
}: InstallPWAPromptProps) => {
  const [isApple, handleIOSInstallDeclined] = useAppleInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();

  return (
    <div className={styles.wrapper}>
      {isApple && (
        <ApplePrompt
          addHomeInstructionsLabelIos={iosAddHomeInstructionsLabel}
          addHomeInstructionsLabelSafari={safariAddHomeInstructionsLabel}
          bodyLabelIos={iosBodyLabel}
          bodyLabelSafari={safariBodyLabel}
          cancelLabel={appleCancelLabel}
          onClose={handleIOSInstallDeclined}
          shareInstructionsLabelIos={iosShareInstructionsLabel}
          shareInstructionsLabelSafari={safariShareInstructionsLabel}
          titleLabelIos={iosTitleLabel}
          titleLabelSafari={safariTitleLabel}
        />
      )}
      {webInstallPrompt && (
        <WebPrompt
          bodyLabel={webBodyLabel}
          installBtnLabel={webInstallBtnLabel}
          logo={webLogo}
          onAccept={handleWebInstallAccepted}
          onClose={handleWebInstallDeclined}
        />
      )}
    </div>
  );
};
