const getGradient = (color: string, percent: number) =>
  `linear-gradient(to right, ${color} ${percent}%, var(--mdrt-color-borders-dark-01) ${percent}%)`;

export const getSliderColors = (percent: number) => {
  if (percent >= 10 && percent <= 30) {
    return {
      thumbColor: 'var(--mdrt-color-backgrounds-bg-secondary-fill)',
      trackGradient: getGradient('var(--mdrt-color-backgrounds-bg-secondary-fill)', percent),
    };
  } else if (percent > 30 && percent <= 70) {
    return {
      thumbColor: 'var(--mdrt-color-backgrounds-bg-tertiary-fill)',
      trackGradient: getGradient('var(--mdrt-color-backgrounds-bg-tertiary-fill)', percent),
    };
  } else if (percent > 70 && percent <= 100) {
    return {
      thumbColor: 'var(--mdrt-color-text-on-light-action)',
      trackGradient: getGradient('var(--mdrt-color-text-on-light-action)', percent),
    };
  }

  return {
    thumbColor: 'var(--temp-color-neutral-light-40)',
    trackGradient: getGradient('var(--mdrt-color-borders-dark-01)', percent),
  };
};
