'use client';

import classnames from 'classnames';
import { type ImageProps } from 'next/image';

import { DefaultAvatar } from '../assets';
import { ImageWithFallback } from '../image-with-fallback';

import { AvatarSize } from './avatarSize';
import styles from './avatar.module.scss';

type AvatarProps = ImageProps & {
  size?: AvatarSize;
};

export const Avatar = ({ className, size = AvatarSize.Small, sizes, ...rest }: AvatarProps) => {
  const sizeValue = size === AvatarSize.Tiny ? 40 : 80;

  return (
    <ImageWithFallback
      {...rest}
      className={classnames(styles.avatar, styles[`avatar--size-${size}`], className)}
      fallbackSrc={DefaultAvatar}
      height={sizeValue}
      quality={85}
      sizes={sizes ? sizes : `${sizeValue * 3}px`}
      width={sizeValue}
    />
  );
};
