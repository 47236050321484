import * as React from 'react';
import type { SVGProps } from 'react';
const SvgShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 4 19v-9q0-.825.588-1.412A1.93 1.93 0 0 1 6 8h3v2H6v9h12v-9h-3V8h3q.824 0 1.413.588Q20 9.175 20 10v9q0 .824-.587 1.413A1.93 1.93 0 0 1 18 21zm5-7V4.825l-1.6 1.6L8 5l4-4 4 4-1.4 1.425-1.6-1.6V14z"
    />
  </svg>
);
export default SvgShareIcon;
