'use client';

import classNames from 'classnames';
import { type InputHTMLAttributes, forwardRef } from 'react';

import { type Transform } from '../field-controller';

import styles from './form-input.module.scss';

export type InputProps = {
  errorMessageId?: string;
  hasError?: boolean;
  isRequired?: boolean;
  transform?: Transform;
} & InputHTMLAttributes<HTMLInputElement>;

export const FormInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, errorMessageId, hasError, id, isRequired, onChange, transform, value, ...rest },
    ref
  ) => {
    return (
      <input
        aria-describedby={errorMessageId}
        aria-invalid={hasError}
        aria-required={isRequired}
        className={classNames(className, styles['form-input'], {
          [styles['form-input--has-error']]: hasError,
          [styles['form-input--has-value']]: Boolean(value),
        })}
        id={id}
        onChange={(event) => {
          onChange?.(transform ? transform.output(event) : event.target.value);
        }}
        ref={ref}
        value={transform ? transform.input(value) : value}
        {...rest}
      />
    );
  }
);
