import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5 21q-.824 0-1.412-.587A1.93 1.93 0 0 1 3 19V5q0-.824.587-1.412A1.93 1.93 0 0 1 5 3h6q.424 0 .713.288Q12 3.575 12 4q0 .424-.287.713A.97.97 0 0 1 11 5H5v14h6q.424 0 .713.288.287.287.287.712 0 .424-.287.712A.97.97 0 0 1 11 21zm12.175-8H10a.97.97 0 0 1-.713-.287A.97.97 0 0 1 9 12q0-.424.287-.713A.97.97 0 0 1 10 11h7.175L15.3 9.125a.92.92 0 0 1-.275-.675q0-.4.275-.7a.95.95 0 0 1 .7-.312.95.95 0 0 1 .725.287L20.3 11.3q.3.3.3.7t-.3.7l-3.575 3.575a.93.93 0 0 1-.713.288 1.02 1.02 0 0 1-.712-.313.97.97 0 0 1-.263-.713.98.98 0 0 1 .288-.687z"
    />
  </svg>
);
export default SvgLogout;
