import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBiometricIdIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 124 124" {...props}>
    <path
      fill="#FAFAFA"
      d="M0 20C0 8.954 8.954 0 20 0h84c11.046 0 20 8.954 20 20v84c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20z"
    />
    <path
      fill="#1C1C1C"
      fillRule="evenodd"
      d="M36.844 49.367v-6.289a6.24 6.24 0 0 1 6.234-6.234h6.29a1.422 1.422 0 0 0 0-2.844h-6.29C38.072 34 34 38.072 34 43.078v6.29a1.422 1.422 0 0 0 2.844 0m12.523 37.79a1.422 1.422 0 0 1 0 2.843h-6.289C38.072 90 34 85.927 34 80.922v-6.29a1.422 1.422 0 0 1 2.844 0v6.29a6.24 6.24 0 0 0 6.234 6.234zM90 74.632v6.289C90 85.927 85.927 90 80.922 90h-6.29a1.422 1.422 0 0 1 0-2.844h6.29a6.24 6.24 0 0 0 6.234-6.234v-6.29a1.422 1.422 0 0 1 2.844 0m0-31.555v6.29a1.422 1.422 0 0 1-2.844 0v-6.29a6.24 6.24 0 0 0-6.234-6.234h-6.29a1.422 1.422 0 0 1 0-2.844h6.29C85.927 34 90 38.072 90 43.078M71.813 75.39a1.477 1.477 0 1 0-2.004-2.169A11.48 11.48 0 0 1 62 76.273a11.48 11.48 0 0 1-7.809-3.053 1.477 1.477 0 0 0-2.004 2.17A14.42 14.42 0 0 0 62 79.226c3.649 0 7.134-1.363 9.813-3.838m-6.86-20.28v10.5a3.78 3.78 0 0 1-3.773 3.774h-1.203a1.477 1.477 0 0 1 0-2.953h1.203c.452 0 .82-.368.82-.82v-10.5a1.477 1.477 0 0 1 2.953 0m10.172 4.13v-4.212a1.395 1.395 0 0 0-2.79 0v4.211a1.395 1.395 0 0 0 2.79 0m-25.922 0a1.395 1.395 0 0 0 2.79 0v-4.212a1.395 1.395 0 0 0-2.79 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBiometricIdIcon;
