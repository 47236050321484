'use client';

import { type StaticImport } from 'next/dist/shared/lib/get-img-props';
import Image, { type ImageProps } from 'next/image';
import { useState } from 'react';

export type ImageWithFallbackProps = {
  fallbackSrc?: string | StaticImport;
} & ImageProps;

export const ImageWithFallback = ({ fallbackSrc, src, ...rest }: ImageWithFallbackProps) => {
  const [imgSource, setImgSource] = useState(src || fallbackSrc || '');

  return (
    <Image
      {...rest}
      alt={rest.alt}
      onError={() => {
        if (fallbackSrc) {
          setImgSource(fallbackSrc);
        }
      }}
      src={imgSource}
    />
  );
};
