export const DockIcon = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 23.231 17.688" xmlns="http://www.w3.org/2000/svg">
      <g fill="currentColor">
        <path d="M.788 3.064v1.077h21.294V3.064Zm2.09 14.618h17.11c1.915 0 2.882-.97 2.882-2.848V2.854C22.87.977 21.903 0 19.988 0H2.878C.975 0 0 .967 0 2.855v11.979c0 1.887.974 2.848 2.879 2.848Zm.023-1.206c-1.1 0-1.692-.581-1.692-1.709V2.921c0-1.127.592-1.712 1.692-1.712H19.97c1.078 0 1.692.585 1.692 1.712v11.846c0 1.128-.614 1.709-1.692 1.709Z" />
        <path d="M4.02 14.002c0 .507.35.85.87.85h13.116c.521 0 .873-.343.873-.85v-1.365c0-.511-.352-.85-.873-.85H4.89c-.52 0-.87.339-.87.85Z" />
      </g>
    </svg>
  );
};
