import classnames from 'classnames';
import { forwardRef, type HTMLAttributes, type PropsWithChildren } from 'react';

import styles from './card.module.scss';

type CardProps = {
  className?: string;
  elevation?: 0 | 1 | 2;
  hasNoRadius?: boolean;
  hasSmallRadius?: boolean;
  isClickable?: boolean;
  isSecondary?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      className,
      elevation = 1,
      hasNoRadius = false,
      hasSmallRadius = false,
      isClickable = false,
      isSecondary = false,
      ...rest
    }: PropsWithChildren<CardProps>,
    ref
  ) => {
    const PARENT_CLASSNAME = 'card';

    return (
      <div
        className={classnames(
          styles[PARENT_CLASSNAME],
          {
            [styles[`${PARENT_CLASSNAME}--clickable`]]: isClickable,
            [styles[`${PARENT_CLASSNAME}--elevation-${elevation}`]]: !isClickable,
            [styles[`${PARENT_CLASSNAME}--secondary`]]: isSecondary,
            [styles[`${PARENT_CLASSNAME}--small-radius`]]: hasSmallRadius,
            [styles[`${PARENT_CLASSNAME}--no-radius`]]: hasNoRadius,
          },
          className
        )}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
