import { maxLifetimeGranularity } from '../constants';
import { type DonationLevel } from '../types';

import { formatCurrency } from './format-currency';

export const getLifetimeDonationLevels = (donationLevels: DonationLevel[], donation: number) => {
  const lifetimeDonationLevels = donationLevels.filter((level) => level.type === 1);

  if (donation <= maxLifetimeGranularity) {
    return lifetimeDonationLevels.filter((level) => level.amount <= maxLifetimeGranularity);
  }

  return lifetimeDonationLevels.slice(2);
};

export const getDonationAmounts = (donationLevels: DonationLevel[]) =>
  donationLevels.map((level) => level.amount);

export const findNextClosestDonationLevel = (donationLevels: DonationLevel[], donation: number) => {
  if (!donation) return null;

  const currentLevel = donationLevels.find((level) => {
    return donation < level.amount;
  });

  if (donation >= donationLevels[donationLevels.length - 1].amount) return null;

  return currentLevel || null;
};

export const getAmountToReachNextLevel = (
  donationLevel: DonationLevel | null,
  donation: number
) => {
  if (!donationLevel) return 0;

  return formatCurrency(donationLevel.amount - donation);
};
