import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCountdownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentcolor"
      d="M11.667 10.625a.625.625 0 1 1 1.25 0v1.725l1.492.862a.626.626 0 0 1-.626 1.084l-2.116-1.22zM3.333 1.667H15a1.667 1.667 0 0 1 1.667 1.666v5.084a5.8 5.8 0 0 1 1.666 4.083 5.834 5.834 0 0 1-5.833 5.833 5.8 5.8 0 0 1-4.083-1.666H3.333A1.666 1.666 0 0 1 1.667 15V3.333a1.667 1.667 0 0 1 1.666-1.666m0 10.833V15h3.892a5.85 5.85 0 0 1-.558-2.5zm0-5.833h5V3.333h-5zm11.667 0V3.333h-5v3.334zM3.333 10.833h3.575a5.8 5.8 0 0 1 1.509-2.5H3.333zM12.5 8.458a4.042 4.042 0 1 0 0 8.084 4.042 4.042 0 0 0 0-8.084"
    />
  </svg>
);
export default SvgCountdownIcon;
