'use client';

import { useEffect, useState } from 'react';

import { getAppleDeviceInfo } from '../utils';

import { useShouldShowInstallPrompt } from './use-should-show-install-prompt';

export const useAppleInstallPrompt = (): [boolean, () => void] => {
  const [isApple, setIsApple] = useState(false);
  const [userShouldBePromptedToInstall, hideInstallPrompt] =
    useShouldShowInstallPrompt('iosInstallPromptedAt');

  useEffect(() => {
    // @ts-expect-error - expected
    if (!window.navigator.standalone) {
      setIsApple(getAppleDeviceInfo().isApple);
    }
  }, []);

  return [isApple && userShouldBePromptedToInstall, hideInstallPrompt];
};
