import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.493 15.507a1.113 1.113 0 0 0 1.573 0L10 11.573l3.934 3.934a1.113 1.113 0 1 0 1.573-1.573L11.573 10l3.934-3.934a1.113 1.113 0 0 0-1.573-1.573L10 8.426 6.066 4.493a1.113 1.113 0 0 0-1.573 1.573L8.426 10l-3.933 3.934a1.113 1.113 0 0 0 0 1.573"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgCloseIcon;
