'use client';

import { type PropsWithChildren, type ReactElement, useEffect, useState } from 'react';

import { useMounted } from '@shared/utils';

import { Button } from '../button';
import { Dialog } from '../dialog';
import { ModalTrigger } from '../modal';

import { InputPreview } from './components';
import styles from './account-settings-preview-modal.module.scss';

export type SectionsData = Array<{
  sectionItems: Array<{
    label: string;
    value: string;
  }>;
  sectionLinkComponent: ReactElement;
  sectionTitle: string;
}>;

type AccountSettingsPreviewModalProps = {
  cmsContent: {
    buttonLabel: string;
    contactSectionTitle: string;
    modalDescription: string;
    personalSectionTitle: string;
    professionalSectionTitle: string;
    title: string;
  };
  dismissModalHandler: () => void;
  initialIsModalOpen: boolean;
  sectionsData: SectionsData;
};

const FormPreviewSection = ({
  children,
  editLinkComponent,
  title,
}: PropsWithChildren<{
  editLinkComponent: ReactElement;
  title: string;
}>) => {
  return (
    <>
      <div className={styles['preview-modal__form-preview-section-heading']}>
        <h3 className={styles['preview-modal__form-preview-section-title']}>{title}</h3>
        {editLinkComponent}
      </div>
      <dl className={styles['preview-modal__form-preview-items-list']}>{children}</dl>
    </>
  );
};

export const AccountSettingsPreviewModal = ({
  cmsContent,
  dismissModalHandler,
  initialIsModalOpen,
  sectionsData,
}: AccountSettingsPreviewModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMounted = useMounted();

  useEffect(() => {
    if (initialIsModalOpen) {
      setIsModalOpen(true);
    }
  }, [initialIsModalOpen, isMounted]);

  const handleModalDismiss = () => {
    dismissModalHandler();
    setIsModalOpen(false);
  };

  return (
    <ModalTrigger isOpen={isModalOpen}>
      {() => (
        <Dialog
          className={styles['preview-modal']}
          footer={
            <div className={styles['preview-modal__footer']}>
              <Button onClick={handleModalDismiss}>{cmsContent.buttonLabel}</Button>
            </div>
          }
          hasCloseButton={false}
          title={cmsContent.title}
        >
          <section className={styles['preview-modal__description-section']}>
            <p>{cmsContent.modalDescription}</p>
          </section>
          <section className={styles['preview-modal__form-preview-section']}>
            {sectionsData &&
              sectionsData.map((sectionData) => {
                if (sectionData.sectionItems.length) {
                  return (
                    <FormPreviewSection
                      editLinkComponent={sectionData.sectionLinkComponent}
                      key={sectionData.sectionTitle}
                      title={sectionData.sectionTitle}
                    >
                      {sectionData.sectionItems.map((item) => (
                        <InputPreview
                          key={item.label + item.value}
                          label={item.label}
                          value={item.value}
                        />
                      ))}
                    </FormPreviewSection>
                  );
                }

                return null;
              })}
          </section>
        </Dialog>
      )}
    </ModalTrigger>
  );
};
