'use client';

import { type FieldValues, FormProvider, type UseFormReturn } from 'react-hook-form';

type FormWrapperProps<T extends FieldValues> = {
  children: React.ReactNode;
  className?: string;
  dataTestId: string;
  formMethods: UseFormReturn<T>;
  id?: string;
  onSubmit: (data: T) => Promise<void> | void;
};

export const FormWrapper = <T extends FieldValues>({
  children,
  className,
  dataTestId,
  formMethods,
  id,
  onSubmit,
}: FormWrapperProps<T>) => {
  return (
    <FormProvider {...formMethods}>
      <form
        className={className}
        data-testid={dataTestId}
        id={id}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        {children}
      </form>
    </FormProvider>
  );
};
