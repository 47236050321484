'use client';
import { useEffect } from 'react';

export const useConfirmOnWindowUnload = (requireConfirmation: boolean) => {
  useEffect(() => {
    if (window) {
      if (requireConfirmation) {
        window.onbeforeunload = (event) => {
          event.preventDefault();
          event.returnValue = true;
        };
      } else {
        window.onbeforeunload = null;
      }
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [requireConfirmation]);
};
