import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="m10.558 13.936-2.15-2.15a.95.95 0 0 0-.7-.275.95.95 0 0 0-.7.275.95.95 0 0 0-.275.7q0 .424.275.7l2.85 2.85q.3.3.7.3t.7-.3l5.65-5.65a.95.95 0 0 0 .275-.7.95.95 0 0 0-.275-.7.95.95 0 0 0-.7-.275.95.95 0 0 0-.7.275zm1.4 8.2a9.7 9.7 0 0 1-3.9-.788 10.1 10.1 0 0 1-3.175-2.137q-1.35-1.35-2.138-3.175a9.7 9.7 0 0 1-.787-3.9q0-2.075.787-3.9A10.1 10.1 0 0 1 4.883 5.06q1.35-1.35 3.175-2.138a9.7 9.7 0 0 1 3.9-.787q2.074 0 3.9.787a10.1 10.1 0 0 1 3.175 2.138q1.35 1.35 2.137 3.175a9.7 9.7 0 0 1 .788 3.9 9.7 9.7 0 0 1-.788 3.9 10.1 10.1 0 0 1-2.137 3.175q-1.35 1.35-3.175 2.137a9.7 9.7 0 0 1-3.9.788m0-2q3.35 0 5.675-2.325t2.325-5.675-2.325-5.675-5.675-2.325T6.283 6.46t-2.325 5.675 2.325 5.675 5.675 2.325"
    />
  </svg>
);
export default SvgSuccessIcon;
