import * as React from 'react';
import type { SVGProps } from 'react';
const SvgGuideIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 14" {...props}>
    <path
      fill="#000"
      d="M.5 12.5A1.5 1.5 0 0 0 2 14h8a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 10 1H9a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1h1a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 2 2h.5V1H2A1.5 1.5 0 0 0 .5 2.5zM4 2V1h4v1z"
    />
    <path
      fill="#000"
      d="M2.5 4.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M5 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M2.5 6.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M5 6.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M2.5 8.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M5 8.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M2.5 10.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0M5 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"
    />
  </svg>
);
export default SvgGuideIcon;
