import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArticleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" {...props}>
    <path
      fill="currentColor"
      d="M6.423 1.91a.83.83 0 0 1 .589-.243H12.5c.764 0 1.667.902 1.667 1.666v10c0 .764-.903 1.667-1.667 1.667H4.167c-.764 0-1.667-.903-1.667-1.667V6.178c0-.22.088-.433.244-.589zM4.167 13.334H12.5v-10h-5v2.5c0 .46-.373.833-.833.833h-2.5zm12.5-9.166a.833.833 0 0 0-.834.833v11.667h-10a.833.833 0 0 0 0 1.666H16.39c.764 0 1.111-.903 1.111-1.666V5a.833.833 0 0 0-.833-.833"
    />
    <path
      fill="currentColor"
      d="M10.833 7.5H9.167a.833.833 0 0 1 0-1.667h1.666a.833.833 0 1 1 0 1.667M10.833 10H6.667a.833.833 0 0 1 0-1.667h4.166a.833.833 0 1 1 0 1.667M10.833 12.5H6.667a.833.833 0 1 1 0-1.667h4.166a.833.833 0 1 1 0 1.667"
    />
  </svg>
);
export default SvgArticleIcon;
