import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLinkedinIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="linkedin-svg"
    viewBox="0 0 30 30"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 0c8.284 0 15 6.716 15 15s-6.716 15-15 15S0 23.284 0 15 6.716 0 15 0m3.445 12.465c-1.522 0-2.476.908-2.653 1.543v-1.454h-2.986c.011.219.016 1.053.017 2.139v1.342c-.002 2.354-.014 5.115-.016 5.68v.103h2.985v-5.012c0-.28-.012-.558.065-.757.205-.558.646-1.135 1.44-1.135 1.036 0 1.507.855 1.507 2.11v4.794h3.014v-5.151c0-2.868-1.484-4.202-3.373-4.202m-7.261.09H8.526v9.262h2.658zM9.805 8.181c-.981 0-1.623.694-1.623 1.609 0 .897.624 1.605 1.586 1.605h.019c.999 0 1.621-.711 1.621-1.608-.02-.914-.622-1.606-1.603-1.606"
    />
  </svg>
);
export default SvgLinkedinIcon;
