import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBookmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17 3H7c-1.1 0-2 .9-2 2v14.484a1 1 0 0 0 1.394.919L12 18l5.606 2.403a1 1 0 0 0 1.394-.92V5c0-1.1-.9-2-2-2m0 15-5-2.18L7 18V5h10z"
    />
    <path
      fill="currentColor"
      d="m13.65 8.35-2.825 2.825-.7-.7q-.3-.3-.713-.3-.412 0-.712.3-.275.3-.275.713 0 .412.275.687l1.425 1.425q.3.3.7.3t.7-.3l3.55-3.525q.3-.3.3-.713 0-.412-.3-.712t-.712-.3-.713.3"
    />
  </svg>
);
export default SvgBookmarkIcon;
